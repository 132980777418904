import { EditTowingOrderFormValues, Route, EditTowingOrder } from './types'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { toCustomerInput } from '../../Customer/toCustomerInput'
import { TowingOrderType } from '../../../common/types'
import { initialSosServiceOrder } from '../../../components/SosSubForm/types'
import { VehicleInfoDataSource } from '../../../components/VehicleDetails/types'

export function getInitialValues(towingOrder: EditTowingOrder): EditTowingOrderFormValues {
  return {
    id: towingOrder.id,
    assignOnSave: false,
    visibility: towingOrder.visibility,
    stationId: towingOrder.stationId,
    operatorId: towingOrder.operatorId,
    vehicleInfoCheckId: towingOrder.vehicleInfoCheckId,
    label: towingOrder.label,
    orderer: towingOrder.orderer ? toCustomerInput(towingOrder.orderer) : initialOrderer,
    vehicleDetails: towingOrder.vehicleDetails ? toVehicleDetails(towingOrder) : initialVehicleDetails,
    jobDetails: towingOrder.jobDetails,
    from: toRoute(towingOrder.from),
    to: toRoute(towingOrder.to),
    sosServiceOrder:
      towingOrder.type === TowingOrderType.sos && !towingOrder.sosServiceOrder
        ? initialSosServiceOrder
        : towingOrder.sosServiceOrder,
    automobileAndTouringClubFinland: towingOrder.automobileAndTouringClubFinland,
    notificationMethod: towingOrder.notificationMethod,
  }
}

function toVehicleDetails(towingOrder: EditTowingOrder) {
  const searchMeta = towingOrder.vehicleInfoCheck && towingOrder.vehicleInfoCheck.searchMeta 
  return {
    ...towingOrder.vehicleDetails,
    dataSource: searchMeta ? searchMeta.dataSource : VehicleInfoDataSource.default,
    dataSourceLongText: searchMeta ? searchMeta.dataSourceLongText : '',
    dataSourceShortText: searchMeta ? searchMeta.dataSourceShortText : ''
  }
} 

function toRoute(route: Route): Route {
  return {
    address: route.address ? route.address : '',
    city: route.city ? route.city : '',
    description: route.description ? route.description : '',
    coords: route.coords ? route.coords : initialCoordinates,
    zipcode: route.zipcode ? route.zipcode : '',
  }
}

export const initialVehicleDetails = {
  registrationNumber: '',
  vehicleClass: '',
  makeAndModel: '',
  insuranceCompany: '',
  owner: '',
  holder: '',
  dataSource: VehicleInfoDataSource.default,
  dataSourceLongText: '',
  dataSourceShortText: '',
}

const initialCoordinates = {
  lat: 60.183331,
  long: 24.941373,
}
