import { Maybe } from "../../common/types";
import { ownersHolderToCustomerInput } from "../../containers/Customer/toCustomerInput";
import { validateSsn } from "../../util/common";
import { VehicleInfo } from "../VehicleSearchModal/types";
import { OwnersHoldersTrafi } from "./types";

export const setOwnersHoldersData = (res: Maybe<VehicleInfo>, setData: React.Dispatch<React.SetStateAction<OwnersHoldersTrafi[]>>) => {
  if (res) {
    const tempOwners: OwnersHoldersTrafi[] = [];
    if(res.searchMeta && ['trafi', 'sos', 'cache'].indexOf(res.searchMeta.dataSource) < 0){
      return []
    }

    res.ownersHolders.forEach((owner) => {
      if( owner.customer && owner.customer.name === 'Ei saatavilla') return
      if((owner.ownershipType === 1 || owner.ownershipType === 2 || owner.ownershipType === 3 || owner.ownershipType === 4) && owner.customer && owner.customer.name){
        const discardFaultySsn = owner.customer.type === 'person' && !validateSsn(owner.customer.identifier)
        
        tempOwners.push({
          value: ownersHolderToCustomerInput(owner, discardFaultySsn),
          label:`${owner.ownershipTypeDescription} / ${owner.customer.name}`
        })
      }
    })
    setData(tempOwners)
  }
}