import React, { memo, useContext, useState } from 'react'
import { FormatMessageType, Maybe, TowingOrderType } from '../../../common/types'
import {
  FormPadder,

  FormSubGroupsList,
 
} from '../../../components/layout'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { SosServiceOrderDetailsForm } from '../../../components/SosSubForm/TowingOrderForm/AddTowingOrder'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import { CustomerFormFields } from '../../Customer/CustomerFormFields'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { EditCustomer } from '../../Customer/EditCustomer'
import { CustomerFormValues } from '../../Customer/types'
import { calculateStationCount } from '../../Parameters/station'
import { StateStore } from '../../StoreProvider'
import { TowingOrderSaveButtons } from '../components/SaveButtons'

import { VisibilityType } from '../types'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { TowingOrderDetailsSubFormContents } from './TowingOrderDetailsSubFormContents'
import { TowingOrderRoutesForm } from './TowingOrderRoutesForm'
import { TowingOrderSettingsForm } from './TowingOrderSettingsForm'
import { VehicleInfoFormFields } from '../../../components/VehicleDetails/VehicleInfoFormFields'

interface FormContentsProps {
  submitForm: (assign: boolean, operatorId: Maybe<number>, visibility: Maybe<VisibilityType>) => void
  formatMessage: FormatMessageType
  onCancelVehicleDetails: () => void
  onHandleVehicleResults: (res: VehicleInfo) => void
  onSetOrderer: (customer: CustomerFormValues) => void
  operatorDisabled: boolean
  closeForm: () => void
  showTowingDateSpan: boolean
  orderer: CustomerFormValues
  ssn: string
  businessId: string
  registrationNumber: string
  setFieldValue: (field: string, value: any) => void
  getValue: any
  isSos: boolean
  ownersHolders: OwnersHoldersTrafi[]
  setOrdererFromTrafi: (customer: CustomerFormValues) => void
}

export const TowingOrderFormContentsMemo: React.FunctionComponent<FormContentsProps> = ({
  formatMessage,
  onCancelVehicleDetails,
  onHandleVehicleResults,
  onSetOrderer,
  showTowingDateSpan,
  orderer,
  operatorDisabled,
  ssn,
  registrationNumber,
  setFieldValue,
  submitForm,
  getValue,
  isSos,
  businessId,
  ownersHolders,
  setOrdererFromTrafi,
}) => {
  const [showCustomerEditForm, setShowCustomerEditForm] = useState<boolean>(false)

  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  const stationRequired = calculateStationCount(state.settings.stations) > 0

  const userId = state.currentUser ? state.currentUser.userId : null
  const userRole = state.currentUser ? state.currentUser.role : null
  const operatorId = getValue('operatorId')
  const useExtendedRoutes = state.settings.modules.useExtendedRoutes ? true : false

  return (
    <>
      {showCustomerEditForm && (
        <EditCustomer
          orderer={orderer}
          onClose={() => setShowCustomerEditForm(false)}
          onUpdate={updatedOrderer => {
            onSetOrderer(updatedOrderer)
            setShowCustomerEditForm(false)
          }}
        />
      )}
      <Form>
        <FormSubGroupsList>
          <TowingOrderSettingsForm
            operatorDisabled={operatorDisabled}
            stationRequired={stationRequired}
            notificationSettings={state.settings.notifications}
            userRole={userRole}
            isSos={isSos}
          />
          <VehicleInfoFormFields
            formatMessage={formatMessage}
            onHandleVehicleResults={onHandleVehicleResults}
            onCancelVehicleDetails={onCancelVehicleDetails}
            registrationNumber={registrationNumber}
            getValue={getValue}
            setValue={setFieldValue}
          />
          <CustomerFormFields
            getValue={getValue}
            editCustomer={() => setShowCustomerEditForm(true)}
            orderer={orderer}
            clearOrderer={() => onSetOrderer(initialOrderer)}
            onUpdate={updatedOrderer => onSetOrderer(updatedOrderer)}
            ssn={ssn}
            setFieldValue={setFieldValue}
            isSos={isSos}
            businessId={businessId}
            ownersHolders={ownersHolders}
            setOrdererFromTrafi={setOrdererFromTrafi}
          />
          {!isSos ? (
            <TowingOrderDetailsSubFormContents
              formatMessage={formatMessage}
              showTowingDateSpan={showTowingDateSpan}
              showEstimatedServiceType={useExtendedRoutes}
            />
          ) : (
            <SosServiceOrderDetailsForm getValue={getValue} setValue={setFieldValue} />
          )}
          <TowingOrderRoutesForm
            setValue={setFieldValue}
            getValue={getValue}
            orderType={isSos ? TowingOrderType.sos : TowingOrderType.default}
            deliverableName="sosServiceOrder.estimatedServiceType"
            disableDestinationRoute={disableDestination(isSos, getValue)}
          />
        </FormSubGroupsList>
        <FormPadder>
          <TowingOrderSaveButtons
            userId={userId}
            userRole={userRole}
            operatorId={operatorId}
            handleSubmit={submitForm}
          />
        </FormPadder>
        <ScrollToError />
      </Form>
    </>
  )
}

export const TowingOrderFormContents = memo(TowingOrderFormContentsMemo)

function disableDestination(isSos: boolean, getValue: (values: string) => string) {
  if (isSos) {
    const destinationRequired = [null, 'Towage', 'Transport']
    return !destinationRequired.includes(getValue('sosServiceOrder.estimatedServiceType'))
  }

  const destinationRequired = ['towing', 'other']
  return !destinationRequired.includes(getValue('jobDetails.serviceType'))
}
