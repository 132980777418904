import { CustomerType, DeliveryMethod, Maybe, NotificationMethod } from '../../common/types'
import { CustomerOriginatorApp } from '../../functions/graphqlApi/types/customer'
import {
  ScalarBusinessID,
  ScalarEmail,
  ScalarPersonalIdentityCode,
  ScalarPhone,
  ScalarPostalCode,
} from '../../graphql-client/types'

export interface PersonCustomerFormValues {
  ssn: string
  maskedSsn?: Maybe<string>
  firstName: string
  lastName: string
  email: string
  phone: string
  address: string
  identifier: string
  extraInfo: string
  additionalInfo: string
  co: string
  zipcode: string
  city: string
  eInvoiceAddress: string
  eInvoiceOperator: string
  deliveryMethod: DeliveryMethod
  displayInCustomerRegistry: boolean
  notificationMethod: Maybe<NotificationMethod>
  originatorApp: Maybe<CustomerOriginatorApp>
  source: string
}

export interface CompanyCustomerFormValues {
  companyName: string
  businessId: string
  contactPerson: string
  contactPersonEmail: string
  phone: string
  email: string
  address: string
  identifier: string
  extraInfo: string
  additionalInfo: string
  co: string
  zipcode: string
  city: string
  eInvoiceAddress: string
  eInvoiceOperator: string
  deliveryMethod: DeliveryMethod
  displayInCustomerRegistry: boolean
  notificationMethod: Maybe<NotificationMethod>
  originatorApp: Maybe<CustomerOriginatorApp>
  source: string
}

export interface CustomerFormValues {
  id: Maybe<number>
  type: CustomerType
  addToRegistry: boolean
  displayInCustomerRegistry: boolean
  ownerHolderId?: number
  personCustomer: Maybe<PersonCustomerFormValues>
  companyCustomer: Maybe<CompanyCustomerFormValues>
}

export type Customer = CompanyCustomer | PersonCustomer

export interface CompanyCustomer {
  id: number
  __typename: 'CompanyCustomer'
  companyName: string
  businessId: Maybe<string>
  contactPerson: string
  contactPersonEmail: Maybe<string>
  phone: Maybe<string>
  email: Maybe<string>
  address: string
  identifier: string
  extraInfo: string
  additionalInfo: string
  co: string
  zipcode: Maybe<string>
  city: Maybe<string>
  eInvoiceAddress: string
  eInvoiceOperator: string
  paymentTerms: number
  deliveryMethod: DeliveryMethod
  type: CustomerType
  displayInCustomerRegistry: boolean
  notificationMethod: Maybe<NotificationMethod>
  originatorApp: Maybe<CustomerOriginatorApp>
  source: string
}

export interface EditCompanyResponse {
  customer: {
    id: number
    companyName: string
    businessId: Maybe<string>
    contactPerson: string
    contactPersonEmail: Maybe<string>
    phone: Maybe<string>
    address: string
    identifier: string
    extraInfo: string
    additionalInfo: string
    co: string
    zipcode: Maybe<string>
    city: Maybe<string>
    eInvoiceAddress: string
    eInvoiceOperator: string
    paymentTerms: number
    deliveryMethod: DeliveryMethod
    displayInCustomerRegistry: boolean
    email: Maybe<string>
    notificationMethod: Maybe<NotificationMethod>
    originatorApp: Maybe<CustomerOriginatorApp>
    source: string
  }
}

export interface PersonCustomer {
  id: number
  __typename: 'PersonCustomer'
  ssn: Maybe<string>
  maskedSsn?: Maybe<string>
  firstName: string
  lastName: string
  phone: Maybe<string>
  email: Maybe<string>
  identifier: string
  extraInfo: string
  additionalInfo: string
  address: string
  co: string
  zipcode: Maybe<string>
  city: Maybe<string>
  eInvoiceAddress: string
  eInvoiceOperator: string
  deliveryMethod: DeliveryMethod
  type: CustomerType
  displayInCustomerRegistry: boolean
  notificationMethod: Maybe<NotificationMethod>
  originatorApp: Maybe<CustomerOriginatorApp>
  source: string
}

export interface EditPersonResponse {
  customer: {
    id: number
    ssn?: Maybe<string>
    maskedSsn?: Maybe<string>
    firstName: string
    lastName: string
    phone: Maybe<string>
    email: Maybe<string>
    address: string
    identifier: string
    extraInfo: string
    additionalInfo: string
    co: string
    zipcode: Maybe<string>
    city: Maybe<string>
    eInvoiceAddress: string
    eInvoiceOperator: string
    deliveryMethod: DeliveryMethod
    displayInCustomerRegistry: boolean
    notificationMethod: Maybe<NotificationMethod>
    originatorApp: Maybe<CustomerOriginatorApp>
    source: string
  }
}

export interface PersonCustomerInputMutation {
  id: Maybe<number>
  customer: {
    ssn: Maybe<ScalarPersonalIdentityCode>
    firstName: Maybe<string>
    lastName: Maybe<string>
    email: Maybe<ScalarEmail>
    phone: Maybe<ScalarPhone>
    address: Maybe<string>
    identifier: Maybe<string>
    extraInfo: string
    additionalInfo: string
    co: string
    zipcode: Maybe<ScalarPostalCode>
    city: Maybe<String>
    eInvoiceAddress: string
    eInvoiceOperator: string
    deliveryMethod: DeliveryMethod
    notificationMethod: Maybe<NotificationMethod>
    source: string
  }
}

export interface CompanyCustomerInputMutation {
  id: Maybe<number>
  customer: {
    companyName: string
    businessId: Maybe<ScalarBusinessID>
    contactPerson: string
    contactPersonEmail: Maybe<ScalarEmail>
    email: Maybe<ScalarEmail>
    phone: Maybe<ScalarPhone>
    address: string
    identifier: string
    extraInfo: string
    additionalInfo: string
    co: string
    zipcode: Maybe<ScalarPostalCode>
    city: Maybe<String>
    eInvoiceAddress: string
    eInvoiceOperator: string
    deliveryMethod: DeliveryMethod
    notificationMethod: Maybe<NotificationMethod>
    source: string
  }
}

export type CustomerInputMutation = Maybe<CompanyCustomerInputMutation | PersonCustomerInputMutation>

export enum CustomerSource {
  traficom = 'traficom',
  default = ''
}