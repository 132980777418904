import ApolloClient from 'apollo-client'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Maybe } from '../../common/types'
import { RouteHeader } from '../../containers/TowingRecordPage/components/routes/subforms/RouteHeader'
import { HalfButton } from '../Button'
import { Section, SectionColumn, Text } from '../layout'
import { HeaderContainer, ModalContainer, ModalWindow } from '../Map'
import { setErrorNotification } from '../notification'
import { Loading } from '../responses'
import { getVehicleInfo, isUpdateVehicleInfoRelationError, isVehicleInfoError, queryVehicleInfo, updateTowingOrderVehicleInfoRelation, updateTowingRecordVehicleInfoRelation } from './query'
import TrafiSearch from './TrafiSearch'
import { VehicleInfo } from './types'

interface Props {
  vehicleRegistration: string
  client: ApolloClient<any>
  close: () => void
  vehicleInfoCheckId: Maybe<number>
  buttonText?: string
  buttonHandler?: (vehicleInfo: VehicleInfo) => void
  getValue: any,
  setValue: (field: string, value: any) => void
}

export const ModalText = styled.div`
  margin: 1rem 0 1rem 0;
`

async function updateVehiclInfoCheckRelation(client: ApolloClient<any>, getValue: any, vehicleInfoCheckId: number) {
  
  const id = getValue('id')
  if(!id) return null

  const routes = getValue('routes')
  const typename = routes ? "TowingRecord" : "TowingOrder"

  if(typename === "TowingRecord") {
      return await updateTowingRecordVehicleInfoRelation(client, id, vehicleInfoCheckId) 
  }
  if(typename === "TowingOrder") {
    return await updateTowingOrderVehicleInfoRelation(client, id, vehicleInfoCheckId)  
  }
  return null
}

const VehicleSearchModal: React.FunctionComponent<Props> = ({
  vehicleRegistration = '',
  client,
  close,
  vehicleInfoCheckId,
  buttonText,
  buttonHandler,
  getValue,
  setValue
}) => {
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo | null>(null)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = useCallback(
    async (reload: boolean = false) => {
      try {
        setLoading(true)
        const result =
          vehicleInfoCheckId && !reload
            ? await getVehicleInfo(client, vehicleInfoCheckId)
            : await queryVehicleInfo(client, vehicleRegistration, reload)

        setError('')

        // if we not successfull
        if (isVehicleInfoError(result)) {
          setErrorNotification('Haku epäonnistui', result.error.message)
          setError(result.error.message)
          setLoading(false)
          return
        }
        setVehicleInfo(result.vehicleInfo)

        const response = await updateVehiclInfoCheckRelation(client, getValue, result.vehicleInfo.id)
        if(!response || isUpdateVehicleInfoRelationError(response)) {
          return
        }
        setValue("lastModified", response.lastModified);
      } catch (error) {
        console.log(error)
        setError('Haussa tapahtui virhe')
        setErrorNotification('Virhe', 'Haussa tapahtui virhe')
      } finally {
        setLoading(false)
      }
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, vehicleRegistration, setLoading, vehicleInfoCheckId] //missing getValue, setValue
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  const reFetch = useCallback(() => {
    fetch(true)
  }, [fetch])

  const onClick = useCallback(() => {
    if (buttonHandler && vehicleInfo) {
      buttonHandler(vehicleInfo)
    }
    close()
  }, [vehicleInfo, buttonHandler, close])

  return (
    <>
      <ModalContainer>
        <ModalWindow>
          <HeaderContainer>
            <RouteHeader cancel={close} marginBottom="0" />
          </HeaderContainer>
          <Container>
            {error && <WarningText size="medium">{error}</WarningText>}
            {vehicleInfo && vehicleInfo.searchMeta && (
              <WarningText size="medium">
                <b>Huom!</b> {' '}
                {vehicleInfo.searchMeta.dataSourceLongText}
              </WarningText>
            )}
            <Section justify="text" columns={1} height="auto">
              <TrafiButtonSectionColumn alignItems="start">
                {vehicleInfo && buttonText && (
                  <TrafiButton category="save" size="s" onClick={onClick}>
                    {buttonText}
                  </TrafiButton>
                )}
                {vehicleInfo && vehicleInfo.searchMeta && (
                  <TrafiButton category="danger" size="s" onClick={reFetch}>
                    Hae Traficomista
                  </TrafiButton>
                )}
              </TrafiButtonSectionColumn>
            </Section>
            {vehicleInfo && <TrafiSearch vehicleInfo={vehicleInfo} reFetch={reFetch} />}
          </Container>
          <Loading loading={loading} />
        </ModalWindow>
      </ModalContainer>
    </>
  )
}

export default VehicleSearchModal

const Container = styled.div`
  padding: 0 1rem 0.5rem 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0 0.5rem;
  }
`

const WarningText = styled(Text)`
  margin-bottom: 0.5rem;
`

const TrafiButton = styled(HalfButton)`
  width: calc(16% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.tablet}) {
    width: calc(25% - 1rem);
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: 100%;
    max-width: 100%;
  }
  color: white;
`

const TrafiButtonSectionColumn = styled(SectionColumn)`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
  grid-gap: 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-gap: 0.5rem;
  }
`
