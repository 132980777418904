import { FieldProps, getIn } from 'formik'
import React, { useCallback, useState, useEffect } from 'react'
import { Maybe } from '../../../../../common/types'
import { Error, FieldContainer, FormikField as Field, Label } from '../../style'

type Props = OwnProps & FieldProps

interface OwnProps {
  id: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export function BusinessIdField(props: Props): JSX.Element {
  const { field, form, id, label, placeholder, required, disabled } = props

  const fieldValue: Maybe<string> = field.value

  const [value, setValue] = useState<Maybe<string>>(() => {
    if (fieldValue) {
      return fieldValue.toString()
    }

    return ''
  })

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      const trimmedValue = targetValue.trim()

      form.setFieldValue(field.name, trimmedValue === '' ? null : trimmedValue)
      form.setFieldTouched(field.name, true)
    },
    [form, field]
  )

  useEffect(() => {
    if (field.value !== value) {
      setValue(field.value)
    }
  },        [field.value, value])

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <FieldContainer>
      <Label required={required} htmlFor={field.name}>
        {label}
      </Label>

      <Field disabled={disabled} type="text" id={id} name={field.name} placeholder={placeholder} value={value} onChange={handleChange} />

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}
