import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import { BadRequestError, UnauthorizedError } from '../../errorTypes'
import { Customer } from '../types'

export const GET_CUSTOMER_FROM_OWNER_HOLDER_QUERY = gql`
  query getCustomerFromOwnerHolder($id: Int!) {
    getCustomerFromOwnerHolder(id: $id) {
      __typename
      ... on GetCustomerSuccess {
        customer {
          ... on CompanyCustomer {
            id
            companyName
            businessId
            contactPerson
            contactPersonEmail
            phone
            address
            email
            identifier
            extraInfo
            co
            address
            zipcode
            city
            eInvoiceAddress
            eInvoiceOperator
            paymentTerms
            deliveryMethod
            additionalInfo
            notificationMethod
            source
          }

          ... on PersonCustomer {
            id
            ssn
            maskedSsn
            firstName
            lastName
            email
            phone
            address
            extraInfo
            address
            city
            identifier
            co
            zipcode
            eInvoiceAddress
            eInvoiceOperator
            paymentTerms
            deliveryMethod
            additionalInfo
            notificationMethod
            source
          }
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`

export interface GetCustomerFromOwnerHolderSuccess {
  __typename: string
  customer: Customer
}

export type GetCustomerFromOwnerHolderResponse = GetCustomerFromOwnerHolderSuccess | BadRequestError | UnauthorizedError

export const getCustomerFromOwnerHolder = async (
  client: ApolloClient<any>,
  ownerHolderId: number
): Promise<GetCustomerFromOwnerHolderSuccess> => {
  const { data } = await client.query({
    query: GET_CUSTOMER_FROM_OWNER_HOLDER_QUERY,
    variables: { id: ownerHolderId },
    fetchPolicy: 'network-only',
  })

  const result = data.getCustomerFromOwnerHolder

  if (result.__typename !== 'GetCustomerSuccess') {
    return result
  }

  return result
}
