import gql from 'graphql-tag'

export const EDIT_PERSON_CUSTOMER = gql`
  mutation EditPersonCustomer($input: EditPersonCustomerInput!) {
    editPersonCustomer(input: $input) {
      __typename
      ... on EditPersonCustomerSuccess {
        customer {
          id
          ssn
          maskedSsn
          firstName
          lastName
          email
          phone
          address
          extraInfo
          address
          city
          identifier
          co
          zipcode
          eInvoiceAddress
          eInvoiceOperator
          paymentTerms
          deliveryMethod
          additionalInfo
          notificationMethod
          source
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
