import React from 'react'
import styled from 'styled-components'
import {
  Maybe,
  Time,
  TowingRecordRouteType,
  TowingRecordServiceType,
  TowingRecordType,
  User,
} from '../../../../common/types'
import { Icon, IconType } from '../../../../components/icons/Icon'
import { RoutesMenuContainer, RoutesMenuItem, RoutesMenuLabel } from '../../../../components/layout'
import { Styling } from '../../../../components/Styling'
import { RouteMenuConfig } from './createRouteMenuConfig'
import { EditRouteSubforms } from './types'
import { TowingRecord } from '../../ViewTowingRecord/types'

const IconContainer = styled.div`
  margin-bottom: 0.5rem;
`

interface RoutesMenuProps {
  onClick: (id: EditRouteSubforms, done: boolean) => void
  routes: RouteMenuConfig[]
  towingRecord: TowingRecord
  user: Maybe<User>
  canEditRoutes: boolean
  useExtendedRoutes: boolean
}

export const RoutesMenu: React.FunctionComponent<RoutesMenuProps> = ({
  onClick,
  routes,
  towingRecord,
  canEditRoutes,
  useExtendedRoutes,
}) => {
  const shouldDisableFinishRoute = disableFinishRoute(towingRecord, useExtendedRoutes)
  const filteredRoutes = filterRoutes(routes, shouldDisableFinishRoute)

  return (
    <RoutesMenuContainer columnCount={filteredRoutes.length}>
      {filteredRoutes.map(route => (
        <RouteGroup
          key={route.routeId}
          label={route.label}
          routeId={route.routeId}
          onClick={onClick}
          time={route.time}
          done={route.done}
          disabled={!canEditRoutes}
          timeOfCompletion={route.timeOfCompletion}
          timeOfCompletionRequired={[TowingRecordRouteType.arrival, TowingRecordRouteType.finish].includes(route.routeId) && (!!towingRecord.sosServiceOrder || useExtendedRoutes)}
        />
      ))}
    </RoutesMenuContainer>
  )
}

interface RouteGroupProps {
  onClick: (id: EditRouteSubforms, done: boolean) => void
  routeId: TowingRecordRouteType
  label: string
  time: Maybe<Time>
  timeOfCompletion: Maybe<Time>
  timeOfCompletionRequired: boolean
  done: boolean
  disabled: boolean
}

const RouteGroup: React.FunctionComponent<RouteGroupProps> = ({
  routeId,
  label,
  onClick,
  time,
  done,
  disabled,
  timeOfCompletion,
  timeOfCompletionRequired
}) => {
  const iconType = getIcon(routeId)
  const color = getColor(done, disabled, timeOfCompletionRequired, time, timeOfCompletion)

  const onRouteMenuClick = () => {
    if (disabled) return
    onClick(routeId, done)
  }

  return (
    <RoutesMenuItem disabled={disableButton(timeOfCompletion, timeOfCompletionRequired, done)} onClick={onRouteMenuClick}>
      <IconContainer>
        <Icon size="large" color={color} icon={iconType} />
      </IconContainer>
      <RoutesMenuLabel color={color}>{label}</RoutesMenuLabel>
      <Styling bold fontSize="m" color={color} textAlign="center">
        {done && time}
        {done && timeOfCompletion ? ` - ${timeOfCompletion}` : ''}
      </Styling>
    </RoutesMenuItem>
  )
}

function getIcon(routeId: TowingRecordRouteType): IconType {
  switch (routeId) {
    case 'start':
      return 'circle-arrow-right'
    case 'arrival':
      return 'map-marker'
    case 'finish':
      return 'thumbs-up'
    case 'end':
      return 'done'
    default:
      return 'map-marker'
  }
}

function getColor(done: boolean, disabled: boolean, timeOfCompletionRequired: boolean, time: Maybe<Time>, timeOfCompletion: Maybe<Time>){
  if(disabled) return 'red400';

  if(!timeOfCompletionRequired) {
    return done ? 'grey400' : 'green800'
  }

  if(time && !timeOfCompletion) {
    return 'yellow700'
  }

  if(time && timeOfCompletion) {
    return 'grey400'
  } 

  return 'green800'
}

function disableButton(timeOfCompletion: Maybe<Time>, timeOfCompletionRequired: boolean, done: boolean) {
  if(!timeOfCompletionRequired) return done

  if(!timeOfCompletion) return false

  return done
}

function filterRoutes(routes: RouteMenuConfig[], disableFinishRoute: boolean) {
  return routes.filter(route => (disableFinishRoute && route.routeId === 'finish' ? false : true))
}

export function disableFinishRoute(towingRecord: TowingRecord, useExtendedRoutes: boolean) {
  if (towingRecord.sosServiceOrder) {
    return shouldDisableFinishRoute(towingRecord.sosServiceOrder.deliverable as string)
  }

  if (towingRecord.automobileAndTouringClubFinland && useExtendedRoutes) {
    return ![TowingRecordServiceType.TOWING].includes(towingRecord.jobDetails.serviceType)
  }

  if (towingRecord.type === TowingRecordType.default && useExtendedRoutes) {
    return ![TowingRecordServiceType.TOWING, TowingRecordServiceType.OTHER].includes(
      towingRecord.jobDetails.serviceType
    )
  }

  return false
}

export function shouldDisableFinishRoute(deliverable: string) {
  if (deliverable === 'Towage' || deliverable === 'Transport' || deliverable === 'Empty ride') {
    return false
  }

  return true
}
