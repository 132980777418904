import { CustomerFormValues } from '../../containers/Customer/types'

export interface VehicleDetails {
  registrationNumber: string
  vehicleClass: string
  makeAndModel: string
  insuranceCompany: string
  owner: string
  holder: string
  dataSource: string
  dataSourceLongText: string
  dataSourceShortText: string
}

export interface OwnersHoldersTrafi {
  value: CustomerFormValues
  label: string
}

export enum VehicleInfoDataSource {
    traficom = 'trafi',
    default = ''
}