import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import { BadRequestError, ResourceNotFoundError, UnauthorizedError } from '../../containers/errorTypes'
import { VehicleInfo } from './types'
import { MutationHookOptions, useMutation } from 'react-apollo'
import { DocumentNode } from 'graphql'

export const SEARCH_VEHICLE_RELATED_TOWINGORDERS_QUERY = gql`
  query towingOrder($id: Int!) {
    towingOrder(id: $id) {
      __typename
      ... on GetTowingOrderSuccess {
        towingOrder {
          id
          status
        }
      }
    }
  }
`

export const SEARCH_VEHICLE_RELATED_TOWINGRECORDS_QUERY = gql`
  query towingRecord($id: Int!) {
    towingRecord(id: $id) {
      __typename
      ... on GetTowingRecordSuccess {
        towingRecord {
          id
          status
          label
          jobDetails {
            towingDate
          }
          vehicleDetails {
            owner
          }
        }
      }
    }
  }
`

export const vehicleInfoFragment = {
  success: gql`
    fragment VehicleInfoFields on VehicleInfo {
      id
      searchMeta {
        dataSource
        dataSourceLongText
        dataSourceShortText
        searchStatus
        trafiSearchPerformer
        trafiSearchDate
        daysSinceTrafiSearch
      }
      vehicle {
        serialNumber
        registrationNumber
        vehicleClass
        vehicleStatus
        brand
        model
        modelYear
        makeAndModel
        color
        fuel
        transmission
        driveTrain
        displacement
        length
        width
        height
        weight
        holderName
        holderAddress
        remarks
        maxNetEffect
        maxNetWeight
        maxTechNetWeight
        initializationDate
        lastInspectionDate
        lastInspectionResult
        lastInspectionResultDescription
        firstRegistrationDate
        nextInspectionDate
      }
      insurance {
        insuredParty
        insuranceCompany
        insuranceStartDate
        insuranceEndDate
      }
      ownersHolders {
        holdershipType
        ownershipType
        ownershipTypeDescription
        ownershipStartDate
        ownershipEndDate
        customer {
          id
          identifier
          name
          address
          zipcode
          city
          type
        }
      }
      restrictions {
        type
        description
        seriousnessLevel
        seriousnessDescription
      }
      transferPermit {
        purposeOfUse
        validityStarts
        validityEnds
        applicant {
            lastNameMainUnit
            firstNameSubUnit
            streetAddress
            postalCode
            city
        }
        route
      }
    }
  `,
}

export const SEARCH_VEHICLE_QUERY = gql`
  query vehicleInfoSearch($vehicleRegistrationNumber: String!, $refreshDataFromTrafi: Boolean) {
    vehicleInfoSearch(
      vehicleRegistrationNumber: $vehicleRegistrationNumber
      refreshDataFromTrafi: $refreshDataFromTrafi
    ) {
      __typename
      ... on VehicleInfoSuccess {
        vehicleInfo {
          ...VehicleInfoFields
        }
      }
      ... on BadRequestError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
  ${vehicleInfoFragment.success}
`

export const GET_VEHICLE_QUERY = gql`
  query vehicleInfo($vehicleInfoId: Int!) {
    vehicleInfo(vehicleInfoId: $vehicleInfoId) {
      __typename
      ... on VehicleInfoSuccess {
        vehicleInfo {
          ...VehicleInfoFields
        }
      }
      ... on BadRequestError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
  ${vehicleInfoFragment.success}
`

// const expectTypename = (actualTypename: string, expectedTypename: string) => {
//   if (actualTypename !== expectedTypename) {
//     throw new Error(`Failed to query vehicle record. Expected typename '${expectedTypename}', got '${actualTypename}'`)
//   }
// }

// const queryRelatedTowingRecords = (client: ApolloClient<any>, ids: [number]): Promise<RelatedTowingRecord[]> =>
//   Promise.all(
//     ids.map(
//       async (id: number): Promise<RelatedTowingRecord> => {
//         const { data } = await client.query({
//           query: SEARCH_VEHICLE_RELATED_TOWINGRECORDS_QUERY,
//           variables: { id },
//         })

//         expectTypename(data.towingRecord.__typename, 'GetTowingRecordSuccess')

//         return data.towingRecord.towingRecord
//       }
//     )
//   )

// const queryRelatedTowingOrders = (client: ApolloClient<any>, ids: [number]): Promise<RelatedTowingOrder[]> =>
//   Promise.all(
//     ids.map(
//       async (id: number): Promise<RelatedTowingOrder> => {
//         const { data } = await client.query({
//           query: SEARCH_VEHICLE_RELATED_TOWINGORDERS_QUERY,
//           variables: { id },
//         })

//         expectTypename(data.towingOrder.__typename, 'GetTowingOrderSuccess')

//         return data.towingOrder.towingOrder
//       }
//     )
//   )


export interface VehicleInfoResponse {
  __typename: string
  vehicleInfo: VehicleInfo 
}

export type VehicleInfoError = BadRequestError | UnauthorizedError

export const queryVehicleInfo = async (
  client: ApolloClient<any>,
  vehicleRegistration: string,
  reFetch: boolean
): Promise<VehicleInfoResponse | VehicleInfoError> => {
  const { data } = await client.query({
    query: SEARCH_VEHICLE_QUERY,
    variables: { vehicleRegistrationNumber: vehicleRegistration, refreshDataFromTrafi: reFetch },
    fetchPolicy: 'network-only',
  })
  const result = data.vehicleInfoSearch

  if(isVehicleInfoError(result)){
    return result
  }
  //const vehicleInfo = data.vehicleInfoSearch.vehicleInfo
  //expectTypename(data.vehicleInfoSearch.__typename, 'VehicleInfoSuccess')

  // if (result.vehicleInfo && result.vehicleInfo.searchMeta) {
  //   result.vehicleInfo.searchMeta.relatedTowingRecords = await queryRelatedTowingRecords(
  //     client,
  //     result.vehicleInfo.searchMeta.relatedTowingRecords
  //   )

  //   result.vehicleInfo.searchMeta.relatedTowingOrders = await queryRelatedTowingOrders(
  //     client,
  //     result.vehicleInfo.searchMeta.relatedTowingOrders
  //   )
  // }

  return result
}

export const getVehicleInfo = async (client: ApolloClient<any>, vehicleInfoId: number): Promise<VehicleInfoResponse | VehicleInfoError> => {
  const { data } = await client.query({
    query: GET_VEHICLE_QUERY,
    variables: { vehicleInfoId },
    fetchPolicy: 'network-only',
  })

  const result = data.vehicleInfo

  if(isVehicleInfoError(result)){
    return result
  }

  //expectTypename(result.vehicleInfo.__typename, 'VehicleInfoSuccess')

  // if (result.vehicleInfo && result.vehicleInfo.searchMeta) {
  //   result.vehicleInfo.searchMeta.relatedTowingRecords = await queryRelatedTowingRecords(
  //     client,
  //     result.vehicleInfo.searchMeta.relatedTowingRecords
  //   )

  //   result.vehicleInfo.searchMeta.relatedTowingOrders = await queryRelatedTowingOrders(
  //     client,
  //     result.vehicleInfo.searchMeta.relatedTowingOrders
  //   )
  // }

  return result
}

export function isVehicleInfoError(x: any): x is VehicleInfoError{
  return !(x.__typename === 'VehicleInfoSuccess')
}

export const UPDATE_TOWING_RECORD_VEHICLE_INFO_RELATION = gql`
  mutation UpdateTowingRecordVehicleInfoRelation($input: UpdateVehicleInfoCheckRelationInput!) {
    updateTowingRecordVehicleInfoRelation(input: $input)
    {
      __typename
      ... on UpdateVehicleInfoCheckRelationSuccess {
          lastModified
      }
      ... on  ResourceNotFoundError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
`


export type VehicleUpdatedRelationError = ResourceNotFoundError | UnauthorizedError

export interface VehicleInfoUpdateRelationResponse {
  __typename: string
  lastModified: string
  success: boolean 
}

export function isUpdateVehicleInfoRelationError(x: any): x is VehicleUpdatedRelationError{
  return !(x.__typename === 'UpdateVehicleInfoCheckRelationSuccess')
}

export const updateTowingRecordVehicleInfoRelation = async (
  client: ApolloClient<any>,
  towingRecordId: number,
  vehicleInfoCheckId: number
): Promise<VehicleInfoUpdateRelationResponse | VehicleUpdatedRelationError> => {

  const input = {
     towingRecordId: towingRecordId, vehicleInfoCheckId: vehicleInfoCheckId
  }

  const { data } = await client.mutate({
    mutation: UPDATE_TOWING_RECORD_VEHICLE_INFO_RELATION,
    variables: { input },
  })
  const result = data.updateTowingRecordVehicleInfoRelation

  if(isUpdateVehicleInfoRelationError(result)){
    return result
  }

  return result
}


export const UPDATE_TOWING_ORDER_VEHICLE_INFO_RELATION = gql`
  mutation UpdateTowingOrderVehicleInfoRelation($input: UpdateVehicleInfoCheckRelationInput!) {
    updateTowingOrderVehicleInfoRelation(input: $input)
    {
      __typename
      ... on UpdateVehicleInfoCheckRelationSuccess {
          lastModified
      }
      ... on  ResourceNotFoundError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
`
export const updateTowingOrderVehicleInfoRelation = async (
  client: ApolloClient<any>,
  towingOrderId: number,
  vehicleInfoCheckId: number
): Promise<VehicleInfoUpdateRelationResponse | VehicleUpdatedRelationError> => {

  const input = {
    towingOrderId: towingOrderId, vehicleInfoCheckId: vehicleInfoCheckId
  }

  const { data } = await client.mutate({
    mutation: UPDATE_TOWING_ORDER_VEHICLE_INFO_RELATION,
    variables: { input },
  })
  const result = data.updateTowingOrderVehicleInfoRelation

  if(isUpdateVehicleInfoRelationError(result)){
    return result
  }

  return result as VehicleInfoUpdateRelationResponse
}



export const RESET_TOWING_RECORD_VEHICLE_INFO_CHECK = gql`
  mutation ResetTowingRecordVehicleInfoCheck($input: ResetTowingRecordVehicleInfoCheckInput!) {
    resetTowingRecordVehicleInfoCheck(input: $input)
    {
      __typename
      ... on ResetVehicleInfoCheckSuccess {
          lastModified
      }
      ... on  ResourceNotFoundError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
`


export type ResetVehicleInfoCheckError = ResourceNotFoundError | UnauthorizedError

export interface ResetVehicleInfoCheckResponse {
  __typename: string
  lastModified: string
  success: boolean 
}

export function isResetVehicleInfoCheckError(x: any): x is VehicleUpdatedRelationError{
  return !(x.__typename === 'ResetVehicleInfoCheckSuccess')
}

export const resetTowingRecordVehicleInfoCheck = async (client: ApolloClient<any>,towingRecordId: number) => {

  const input = {
    towingRecordId: towingRecordId
  }
  
  const { data } = await client.mutate({
    mutation: RESET_TOWING_RECORD_VEHICLE_INFO_CHECK,
    variables: { input },
  })
  const result = data.resetTowingRecordVehicleInfoCheck

  if(isResetVehicleInfoCheckError(result)) {
    return result
  }
  return result as ResetVehicleInfoCheckResponse
}



//rename success to ResetVehicleInfoCheckSuccess
export const RESET_TOWING_ORDER_VEHICLE_INFO_CHECK = gql`
  mutation ResetTowingOrderVehicleInfoCheck($input: ResetTowingOrderVehicleInfoCheckInput!) {
    resetTowingOrderVehicleInfoCheck(input: $input)
    {
      __typename
      ... on ResetVehicleInfoCheckSuccess {
          lastModified
      }
      ... on  ResourceNotFoundError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
`

export const resetTowingOrderVehicleInfoCheck = async (client: ApolloClient<any>, towingOrderId: number) => {

  const input = {
    towingOrderId: towingOrderId
  }
  
  const { data } = await client.mutate({
    mutation: RESET_TOWING_ORDER_VEHICLE_INFO_CHECK,
    variables: { input },
  })
  const result = data.resetTowingOrderVehicleInfoCheck

  if(isResetVehicleInfoCheckError(result)) {
    return result
  }
  return result as ResetVehicleInfoCheckResponse
}

export const useResetVehicleInfoCheck = (query: DocumentNode, options: MutationHookOptions) => {
    return useMutation(query, options)
}