import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { Maybe, TowingOrderStatus, TypeIdentifier } from '../../../common/types'
import { ALTimeChangeModal } from '../../../components/ALOrderSubForm/ALTimeChangeModal'
import { EditItem } from '../../../components/EditItem'
import { Heading2, Row, Text } from '../../../components/layout'
import { Styling } from '../../../components/Styling'
import { TowingOrder } from '../../../containers/TowingOrderPage/ViewTowingOrder/types'
import { SchedulingJobDetails, TowingOrderScheduling } from '../../TowingOrderScheduling'
import { VehicleDetails } from '../../VehicleDetails/types'

interface SummaryProps {
  vehicleDetails?: Omit<VehicleDetails, 'dataSource' | 'dataSourceLongText' | 'dataSourceShortText'>
  status?: string
  type?: Maybe<string>
  station?: Maybe<Station>
  towingDate?: Maybe<Date>
  jobDetails?: Maybe<SchedulingJobDetails>
  jobTypeDescription: string
  towingOrder?: TowingOrder
  reFetchData?: () => void
}

interface Station {
  name: string
}

export const SummaryHeader: React.FunctionComponent<SummaryProps> = ({
  vehicleDetails,
  status,
  //type,
  //station,
  jobDetails,
  jobTypeDescription,
  towingOrder,
  reFetchData,
}) => {
  const [showTimeChangeModalAL, setShowTimeChangeModalAL] = useState<boolean>(false)

  const onClose = useCallback(() => {
    setShowTimeChangeModalAL(false)
  }, [])

  const isEditableAlOrder = () => {
    if (towingOrder && towingOrder.typeIdentifier === TypeIdentifier.autoliitto) {
      if (towingOrder.status !== TowingOrderStatus.pending_acceptance) {
        return true
      }
      return false
    }
    return false
  }

  const registrationNumber = vehicleDetails ? vehicleDetails.registrationNumber : '-'
  const makeAndModel = vehicleDetails ? vehicleDetails.makeAndModel : ''

  return (
    <>
      {showTimeChangeModalAL}
      {showTimeChangeModalAL && towingOrder && towingOrder.typeIdentifier === TypeIdentifier.autoliitto && (
        <ALTimeChangeModal
          label="Muuta aika"
          onClose={onClose}
          isVisibleTimeInput={true}
          useQueryOnly={true}
          towingOrder={towingOrder}
          reFetch={reFetchData}
        />
      )}
      <Row justify="center" marginMobile="0" alignItems="center">
        <Heading2>{registrationNumber}</Heading2>
      </Row>
      <Row justify="center" marginMobile="0" alignItems="center">
        <Styling color="orange" uppercase bold spacing="some" textAlign="center">
          <Text size="small">{[status, jobTypeDescription].filter(Boolean).join(' / ')}</Text>
        </Styling>
      </Row>
      {makeAndModel && (
        <Row justify="center" marginMobile="0" alignItems="center">
          <Text>{makeAndModel}</Text>
        </Row>
      )}
      {!isEditableAlOrder() && (
        <Row justify="center" marginMobile="0" alignItems="center">
          {jobDetails && <TowingOrderScheduling jobDetails={jobDetails} />}
        </Row>
      )}
      {jobDetails && isEditableAlOrder() && (
        <Row justify="center" marginMobile="0" alignItems="center">
          <ClickableTimeContainer
            onClick={() => {
              setShowTimeChangeModalAL(true)
            }}
          >
            <TowingOrderScheduling jobDetails={jobDetails} />
            <EditItem />
          </ClickableTimeContainer>
        </Row>
      )}
    </>
  )
}

const ClickableTimeContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-items: center;
  align-items: center;
  flex-direction: row;
`
