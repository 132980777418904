import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { CustomerType, Maybe } from '../../../common/types'
import { Page } from '../../../components/layout'
import { dismissNotifications } from '../../../components/notification'
import { Loading } from '../../../components/responses'
import { useHistoryPush } from '../../../components/router'
import { TopNavigation } from '../../../components/TopNavigation/TopNavigation'
import { setOwnersHoldersData } from '../../../components/VehicleDetails/functions'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import {
  GenericStorageRetrieveFn,
  persistentDelete,
  persistentGet,
  persistentSave,
} from '../../../util/localStorage/localStorage'
import { CustomerFormValues } from '../../Customer/types'
import { StateStore } from '../../StoreProvider'
import { STORED_ADD_TOWING_ORDER } from '../../StoreProvider/OfflineMode'
import { shouldDisableOperator } from '../EditTowingOrder/EditTowingOrderForm'
import { VisibilityType } from '../types'
import { getInitialValues, initialVehicleDetails } from './initialValues'
import { TowingOrderFormContents } from './TowingOrderFormContents'
import { AddTowingOrderFormValues } from './types'

const TOWING_ORDER = STORED_ADD_TOWING_ORDER

interface FormProps {
  addTowingOrder: (values: AddTowingOrderFormValues) => Promise<any>
  loading: boolean
  isSos: boolean
}

export const TowingOrderFormIntl: React.FunctionComponent<FormProps & InjectedIntlProps> = ({
  addTowingOrder,
  loading,
  intl,
  isSos,
}) => {
  const [storeFormValues, setStoreFormValues] = useState<boolean>(true)
  const [ownersHolders, setOwnersHolders] = useState<OwnersHoldersTrafi[]>([])
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const closeForm = () => {
    persistentDelete(TOWING_ORDER)
    historyPush('/')
  }

  const persistenGetTowingOrder: GenericStorageRetrieveFn<AddTowingOrderFormValues> = persistentGet

  const storedTowingOrder = persistenGetTowingOrder(TOWING_ORDER)
  if (storedTowingOrder) {
    storedTowingOrder.jobDetails.towingDate = storedTowingOrder.jobDetails.towingDate
      ? new Date(storedTowingOrder.jobDetails.towingDate)
      : null
  }

  // const translatedVisibilityOptions = translateInputOptions(visibilityOptions, formatMessage)
  const { state } = useContext(StateStore)

  const formValues: AddTowingOrderFormValues = getInitialValues(isSos, state.settings)

  const getOperatorId = () => {
    if (state && state.currentUser && state.settings && state.settings.operators) {
      const currentUser = state.currentUser
      if (state.settings.operators.find((operator) => operator.id === currentUser.userId)) {
        return state.currentUser.userId
      }
    }
    return null
  }

  const operatorDisabled = shouldDisableOperator(state.currentUser)

  return (
    <Page noPadding>
      <Loading loading={loading} />
      <TopNavigation
        label="Peru"
        address="/towing-jobs"
        onNavigate={() => {
          setStoreFormValues(false)
          persistentDelete(TOWING_ORDER)
        }}
      />
      <Formik
        initialValues={formValues}
        validateOnChange={false}
        onSubmit={(values: AddTowingOrderFormValues) => addTowingOrder(values)}
        render={({ values, setValues, isSubmitting, setFieldValue, handleSubmit }) => {
          const onSetOrderer = (o: CustomerFormValues) => {
            const currentOrdererType = values.orderer.type
            values.orderer = Object.assign({}, o)
            values.orderer.type = currentOrdererType

            if (state.settings && state.settings.notifications.enabled) {
              switch (o.type) {
                case CustomerType.person:
                  if (o.personCustomer) {
                    if (o.personCustomer.notificationMethod === 'default') {
                      values.notificationMethod = state.settings.notifications.method
                    } else {
                      values.notificationMethod = o.personCustomer.notificationMethod
                    }
                  }
                  break
                case CustomerType.company:
                  if (o.companyCustomer) {
                    if (o.companyCustomer.notificationMethod === 'default') {
                      values.notificationMethod = state.settings.notifications.method
                    } else {
                      values.notificationMethod = o.companyCustomer.notificationMethod
                    }
                  }
                  break
              }
            }

            setValues(values)
          }

          const setOrdererFromTrafi = (o: CustomerFormValues) => {
            values.orderer = Object.assign({}, o)
            setValues(values)
          }

          const onHandleVehicleResults = (res: VehicleInfo) => {
            if (res) {
              setOwnersHoldersData(res, setOwnersHolders)
              const owner = res.ownersHolders.find(ownerHolder => ownerHolder.ownershipType === 1) || null
              const holder = res.ownersHolders.find(ownerHolder => ownerHolder.ownershipType === 3) || null
              values.vehicleDetails.registrationNumber = res.vehicle.registrationNumber
              values.vehicleDetails.insuranceCompany = res.insurance.insuranceCompany
              values.vehicleDetails.makeAndModel = res.vehicle.makeAndModel
              values.vehicleDetails.owner = owner && owner.customer && owner.customer.name ? owner.customer.name : ''
              values.vehicleDetails.vehicleClass = res.vehicle.vehicleClass
              values.vehicleDetails.holder =
                holder && holder.customer && holder.customer.name ? holder.customer.name : ''
              values.vehicleInfoCheckId = res.id

              values.vehicleDetails.dataSource = res.searchMeta ? res.searchMeta.dataSource : ''
              values.vehicleDetails.dataSourceLongText = res.searchMeta ? res.searchMeta.dataSourceLongText : ''
              values.vehicleDetails.dataSourceShortText = res.searchMeta ? res.searchMeta.dataSourceShortText : ''

              if (isSos && values.sosServiceOrder) {
                values.sosServiceOrder.insuranceCompany = res.insurance.insuranceCompany
              }

              setValues(values)
            }
          }
          const onCancelVehicleDetails = () => {
            setFieldValue('vehicleDetails', initialVehicleDetails)
            setFieldValue('vehicleInfoCheckId', null)
          }

          if (isSubmitting ? false : storeFormValues ? true : false) {
            persistentSave(TOWING_ORDER, values)
          }

          const handleSubmitForm = (assign: boolean, operatorId: Maybe<number>, visibility: Maybe<VisibilityType>) => {
            dismissNotifications()
            values.assignOnSave = assign

            if (operatorId) {
              values.operatorId = operatorId
            } else {
              values.operatorId = assign ? getOperatorId() : null
            }

            if (visibility) {
              values.visibility = visibility
            }

            persistentSave(TOWING_ORDER, values)

            handleSubmit()
          }

          const getValue = (fieldName: string) => {
            const value = getIn(values, fieldName)
            return value
          }

          return (
            <TowingOrderFormContents
              submitForm={handleSubmitForm}
              closeForm={closeForm}
              formatMessage={formatMessage}
              onSetOrderer={onSetOrderer}
              onHandleVehicleResults={onHandleVehicleResults}
              onCancelVehicleDetails={onCancelVehicleDetails}
              showTowingDateSpan={values.jobDetails.towingDateType === 'span'}
              orderer={values.orderer}
              ssn={
                values.orderer.personCustomer && values.orderer.personCustomer.ssn
                  ? values.orderer.personCustomer.ssn
                  : ''
              }
              businessId={
                values.orderer.companyCustomer && values.orderer.companyCustomer.businessId
                  ? values.orderer.companyCustomer.businessId
                  : ''
              }
              registrationNumber={values.vehicleDetails.registrationNumber}
              operatorDisabled={operatorDisabled}
              setFieldValue={setFieldValue}
              getValue={getValue}
              isSos={isSos}
              ownersHolders={ownersHolders}
              setOrdererFromTrafi={setOrdererFromTrafi}
            />
          )
        }}
      />
    </Page>
  )
}

export const TowingOrderForm = injectIntl(TowingOrderFormIntl)
