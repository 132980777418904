import gql from 'graphql-tag'

import { sosServiceOrderFragment } from '../../../components/SosSubForm/types'
import { ResourceNotFoundError, UnauthorizedError } from '../../errorTypes'
import { EditTowingRecord } from './types'
import { vehicleInfoFragment } from '../../../components/VehicleSearchModal/query'

export const EDIT_TOWING_RECORD_QUERY = gql`
  query TowingRecord($id: Int!) {
    towingRecord(id: $id) {
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
        }
      }
      ... on GetTowingRecordSuccess {
        towingRecord {
          id
          recordNumber
          type
          typeIdentifier
          operatorId
          stationId
          vehicleInfoCheckId
          label
          status
          paymentType
          itemsIncludeVat
          lastModified
          notificationMethod
          locked
          vehicleDetails {
            registrationNumber
            vehicleClass
            makeAndModel
            insuranceCompany
            owner
            holder
          }
          jobDetails {
            orderReference
            serviceType
            towingDate
            towingReason
            additionalInfo
            notes
            incidentCaseNo
          }
          jobItems {
            id
            type
            name
            description
            quantity
            unitPrice
            unitPriceHasVatIncluded
            unit
            discount
            discountType
            vat
            vatAmount
            grossAmount
            netAmount
            vatUnit
            vatCategory
          }
          signature {
            type
            signatorName
            signatorEmail
            signatorPhone
            signedAt
            createdAt
            sentAt
            agreements {
              text
              isAgreed
            }
            id
          }
          billing {
            type
            status
            paymentDetails {
              id
              vatAmount
              vatExcludedAmount
              vattedAmount
              isConfirmed
              debtorName
              receiptEmail
              paymentDate
            }
            paymentTerminalTransactions {
              id
              receiptId
              previousTransaction
              transactionStatus
              transactionType
              transactionId
              transactionUniqueId
              authorizationCode
              amount
              referenceNumber
              transactionTime
              panMaskedForCustomer
              panMaskedForClerk
              cardReadingMethod
              terminalId
              terminalName
              merchantReceipt
              customerReceipt
              created
              updated
              deletedAt
            }
          }
          routes {
            id
            arrivalDate
            arrivalTime
            finishedDate
            finishedTime
            type
            ordering
            address
            city
            description
            coords {
              lat
              long
            }
            displayTimestampOnPrintables
            displayFinishedTimestampOnPrintables
          }
          orderer {
            __typename
            id
            displayInCustomerRegistry
            ... on PersonCustomer {
              ssn
              maskedSsn
              firstName
              lastName
              phone
              email
              address
              identifier
              extraInfo
              co
              city
              zipcode
              paymentTerms
              deliveryMethod
              additionalInfo
              notificationMethod
              originatorApp
              source
            }
            ... on CompanyCustomer {
              companyName
              businessId
              contactPerson
              contactPersonEmail
              phone
              address
              identifier
              extraInfo
              co
              zipcode
              city
              eInvoiceAddress
              eInvoiceOperator
              paymentTerms
              deliveryMethod
              email
              additionalInfo
              notificationMethod
              originatorApp
              source
            }
          }
          automobileAndTouringClubFinland {
            jobId
            caseNumber
            serviceChannel
            billingInfo
            personCount
            vehicleMileage
            arcCodes {
              component {
                code
                label
              }
              description {
                code
                label
              }
              completion {
                code
                label
              }
            }
            repairShopId
            distanceTowed
            towingInsistedByCustomer
            delayedTowing
            jobTimestamps {
              estimatedTimeOfArrival {
                date
                time
              }
              actualTimeOfArrival {
                date
                time
              }
              estimatedTimeOfCompletion {
                date
                time
              }
              actualTimeOfCompletion {
                date
                time
              }
            }
          }
          sosServiceOrder {
            ...SosServiceOrderFields
          }
          vehicleInfoCheck {
            ...VehicleInfoFields
          }
        }
      }
    }
  }
  ${sosServiceOrderFragment.success}
  ${vehicleInfoFragment.success}
`

export interface TowingRecordResponse {
  towingRecord: TowingRecordOutput
}

type TowingRecordOutput = TowingRecordSuccess | UnauthorizedError | ResourceNotFoundError

interface TowingRecordSuccess
  extends Readonly<{
    __typename: 'GetTowingRecordSuccess'
    towingRecord: EditTowingRecord
  }> {}
