import { defineMessages } from 'react-intl'

export const trafiSourceDescription = "* = Lähde: Liikenne- ja viestintävirasto Traficom, liikenneasioiden rekisteri."
export const trafiSourceText = "Lähde: Liikenne- ja viestintävirasto Traficom, liikenneasioiden rekisteri."

// translation id's for common enums. See how it works out in practice...

export const towingOrderMessages = defineMessages({
  servicetype_towing: {
    id: 'towing.servicetype_towing',
    defaultMessage: 'towing',
  },
  servicetype_road_service: {
    id: 'towing.servicetype_road_service',
    defaultMessage: 'road service',
  },
  servicetype_battery_service: {
    id: 'towing.servicetype_battery_service',
    defaultMessage: 'battery service',
  },
  servicetype_phone_service: {
    id: 'towing.servicetype_phone_service',
    defaultMessage: 'phone service',
  },
  servicetype_other: {
    id: 'towing.servicetype_other',
    defaultMessage: 'other',
  },
  to_visibility_open: {
    id: 'towingOrder.to_visibility_open',
    defaultMessage: 'open',
  },
  to_visibility_restricted: {
    id: 'towingOrder.to_visibility_restricted',
    defaultMessage: 'restricted',
  },
  to_visibility_assigned: {
    id: 'towingOrder.to_visibility_assigned',
    defaultMessage: 'assigned',
  },
  to_visibility_subcontract: {
    id: 'towingOrder.to_visibility_subcontract',
    defaultMessage: 'subcontract',
  },
})

export const towingRecordMessages = defineMessages({
  tr_status_unfinished: {
    id: 'towingRecord.tr_status_unfinished',
    defaultMessage: 'unfinished',
  },
  tr_status_complete: {
    id: 'towingRecord.tr_status_complete',
    defaultMessage: 'complete',
  },
  tr_status_deleted: {
    id: 'towingRecord.tr_status_deleted',
    defaultMessage: 'deleted',
  },
  tr_status_billing: {
    id: 'towingRecord.tr_status_billing',
    defaultMessage: 'billing',
  },
  tr_status_aggregate: {
    id: 'towingRecord.tr_status_aggregate',
    defaultMessage: 'aggregate',
  },
  tr_servicetype_towing: {
    id: 'towingRecord.tr_servicetype_towing',
    defaultMessage: 'towing',
  },
  tr_servicetype_road_service: {
    id: 'towingRecord.tr_servicetype_road_service',
    defaultMessage: 'road service',
  },
  tr_servicetype_battery_service: {
    id: 'towingRecord.tr_servicetype_battery_service',
    defaultMessage: 'battery service',
  },
  tr_servicetype_phone_service: {
    id: 'towingRecord.tr_servicetype_phone_service',
    defaultMessage: 'phone service',
  },
  tr_servicetype_other: {
    id: 'towingRecord.tr_servicetype_other',
    defaultMessage: 'other',
  },
})
