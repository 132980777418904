import gql from 'graphql-tag'

export const EDIT_COMPANY_CUSTOMER = gql`
  mutation EditCompanyCustomer($input: EditCompanyCustomerInput!) {
    editCompanyCustomer(input: $input) {
      __typename
      ... on EditCompanyCustomerSuccess {
        customer {
          id
          companyName
          businessId
          contactPerson
          contactPersonEmail
          phone
          address
          email
          identifier
          extraInfo
          co
          address
          zipcode
          city
          eInvoiceAddress
          eInvoiceOperator
          paymentTerms
          deliveryMethod
          additionalInfo
          notificationMethod
          source
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
