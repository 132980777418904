import gql from 'graphql-tag'

import { sosServiceOrderFragment } from '../../../components/SosSubForm/types'
import { vehicleInfoFragment } from '../../../components/VehicleSearchModal/query'
import { ResourceNotFoundError, UnauthorizedError } from '../../errorTypes'
import { TowingRecord } from './types'

export const towingRecordFragment = {
  success: gql`
    fragment TowingRecordFields on TowingRecord {
      id
      recordNumber
      type
      typeIdentifier
      operatorId
      stationId
      vehicleInfoCheckId
      label
      status
      jobStatus
      paymentType
      lastModified
      notificationMethod
      locked
      images
      jobStartedAt {
        date
        time
      }
      vehicleDetails {
        registrationNumber
        vehicleClass
        makeAndModel
        insuranceCompany
        owner
        holder
      }
      jobDetails {
        orderReference
        serviceType
        towingDate
        towingReason
        additionalInfo
        notes
      }
      jobItems {
        id
        type
        name
        description
        quantity
        unitPrice
        unitPriceHasVatIncluded
        unit
        discount
        discountType
        vat
        vatAmount
        grossAmount
        netAmount
        vatUnit
        vatCategory
      }
      signature {
        type
        signatorName
        signatorEmail
        signedAt
        createdAt
        agreements {
          text
          isAgreed
        }
        id
      }
      billing {
        type
        status
        paymentDetails {
          id
          vatAmount
          vatExcludedAmount
          vattedAmount
          isConfirmed
          debtorName
          receiptEmail
          paymentDate
        }
      }
      routes {
        id
        arrivalDate
        arrivalTime
        finishedDate
        finishedTime
        type
        ordering
        address
        city
        zipcode
        description
        coords {
          lat
          long
        }
        displayTimestampOnPrintables
        displayFinishedTimestampOnPrintables
        geolocated
      }
      orderer {
        __typename
        id
        ... on PersonCustomer {
          firstName
          lastName
          phone
          source
        }
        ... on CompanyCustomer {
          companyName
          phone
          source
        }
      }
      automobileAndTouringClubFinland {
        jobId
        caseNumber
        serviceChannel
        billingInfo
        personCount
        vehicleMileage
        arcCodes {
          component {
            code
            label
          }
          description {
            code
            label
          }
          completion {
            code
            label
          }
        }
        repairShopId
        distanceTowed
        towingInsistedByCustomer
        delayedTowing
        jobTimestamps {
          estimatedTimeOfArrival {
            date
            time
          }
          actualTimeOfArrival {
            date
            time
          }
          estimatedTimeOfCompletion {
            date
            time
          }
          actualTimeOfCompletion {
            date
            time
          }
        }
        batteryService {
          paymentType
          deliveredBattery
        }
        situationDescription
      }
      sosServiceOrder {
        ...SosServiceOrderFields
      }
      jobTimestamps {
        started {
          date
          time
        }
        stopped {
          date
          time
        }
      }
      vehicleInfoCheck {
        ...VehicleInfoFields
      }
    }
    ${sosServiceOrderFragment.success}
    ${vehicleInfoFragment.success}
  `,
}

export const TOWING_RECORD_QUERY = gql`
  query TowingRecord($id: Int!) {
    towingRecord(id: $id) {
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
        }
      }
      ... on GetTowingRecordSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export interface TowingRecordResponse {
  towingRecord: TowingRecordOutput
}

type TowingRecordOutput = TowingRecordSuccess | UnauthorizedError | ResourceNotFoundError

interface TowingRecordSuccess
  extends Readonly<{
    __typename: 'GetTowingRecordSuccess'
    towingRecord: TowingRecord
  }> {}
