import { getIn } from 'formik'
import React, { RefObject, useContext, useEffect, useState } from 'react'

import {
  FormatMessageType,
  Maybe,
  SignatureType,
  TowingOrderType,
  TowingRecordStatus,
  TowingRecordType,
  TypeIdentifier,
} from '../../../common/types'
import { ALSubForm } from '../../../components/ALSubForm'
import {
  LahitapiolaDefaultOrderDetailsForm,
  LahitapiolaSosOrderDetailsForm,
} from '../../../components/LahitapiolaSubForm'
import { FormSubGroupWrapper, FormSubGroupsList } from '../../../components/layout'

import { Signature } from '../../../components/Signature'
import { SignatureInput } from '../../../components/Signature/types'
import { useSignatureCanvasRef } from '../../../components/Signature/useSignatureCanvasRef'
import { SosServiceOrderDetailsForm } from '../../../components/SosSubForm/TowingRecordForm/EditTowingRecord'
import { TowingRecordItems } from '../../../components/TowingRecordItems'
import { getItemsTotal } from '../../../components/TowingRecordItems/Totals'
import { setOwnersHoldersData } from '../../../components/VehicleDetails/functions'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import { CustomerFormFields } from '../../Customer/CustomerFormFields'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { CustomerFormValues } from '../../Customer/types'
import { StateStore } from '../../StoreProvider'
import { TowingOrderRoutesForm } from '../../TowingOrderPage/AddTowingOrder/TowingOrderRoutesForm'
import { initialVehicleDetails } from '../../TowingOrderPage/EditTowingOrder/initialValues'
import { TowingRecordBilling } from '../components/billing/TowingRecordBilling'
import { populateBillingFieldsByDebtorType } from '../components/billing/TowingRecordBilling/populateBillingFieldsByDebtorType'
import { TowingRecordDetailsSubFormContents } from '../components/jobDetails/TowingRecordJobDetails'
import { TowingRecordSettings } from '../components/settings/form/TowingRecordSettings'
import { EditTowingRecordFormValues } from './types'
import { VehicleInfoFormFields } from '../../../components/VehicleDetails/VehicleInfoFormFields'
import {  RESET_TOWING_RECORD_VEHICLE_INFO_CHECK, useResetVehicleInfoCheck } from '../../../components/VehicleSearchModal/query'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { CenteredLoader } from '../../../components/responses/Loading'

interface TowingRecordFormFields {
  formatMessage: FormatMessageType
  values: EditTowingRecordFormValues
  setValues: (values: any) => void
  translatedServiceTypeOptions: object[]
  setValue: (field: string, value: any) => void
  setOrderer: (orderer: CustomerFormValues) => void
  setShowCustomerEditForm: (value: boolean) => void
  initialTowingRecordValues?: EditTowingRecordFormValues
  scrollTo: string | undefined
  disableOperator?: boolean
  isServiceOrderDraft?: boolean
  vehicleInfoCheck: Maybe<VehicleInfo>
  setOrdererFromTrafi: (customer: CustomerFormValues) => void
  hasFormChanged: () => boolean
}

export const TowingRecordFormFields: React.FunctionComponent<TowingRecordFormFields> = ({
  formatMessage,
  setValue,
  setValues,
  translatedServiceTypeOptions,
  values,
  setOrderer,
  setShowCustomerEditForm,
  scrollTo,
  disableOperator,
  isServiceOrderDraft,
  vehicleInfoCheck,
  setOrdererFromTrafi,
  hasFormChanged,
}) => {
  const [ownersHolders, setOwnersHolders] = useState<OwnersHoldersTrafi[]>([])
  const [resetVehicleInfo, { loading }] = useResetVehicleInfoCheck(RESET_TOWING_RECORD_VEHICLE_INFO_CHECK, {
    onCompleted(data) {
      const result = data.resetTowingRecordVehicleInfoCheck
      resetVehicleFormData()
      setValue('lastModified', result.lastModified)
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    }
  }) 

  const resetVehicleFormData = () => {
    const t = 'vehicleDetails'
    values[t] = Object.assign({}, initialVehicleDetails)
    values.vehicleInfoCheckId = null
    setValues(values)
  }

  const handleResetVehicleInfoCheck = async () => {
    const towingRecordId = getValue('id')
    const vehicleInfoCheckId = getValue("vehicleInfoCheckId")

    if(towingRecordId && vehicleInfoCheckId) {
      resetVehicleInfo({variables: { input: {towingRecordId: towingRecordId} }})
    }
    resetVehicleFormData()
  }

  const handleVehicleResults = (res: VehicleInfo) => {
    if (res) {
      setOwnersHoldersData(res, setOwnersHolders)
      const owner = res.ownersHolders.find(ownerHolder => ownerHolder.ownershipType === 1) || null
      const holder = res.ownersHolders.find(ownerHolder => ownerHolder.ownershipType === 3) || null
      values.vehicleDetails.registrationNumber = res.vehicle.registrationNumber
      values.vehicleDetails.insuranceCompany = res.insurance.insuranceCompany
      values.vehicleDetails.makeAndModel = res.vehicle.makeAndModel
      values.vehicleDetails.owner = owner && owner.customer && owner.customer.name ? owner.customer.name : ''
      values.vehicleDetails.vehicleClass = res.vehicle.vehicleClass
      values.vehicleDetails.holder = holder && holder.customer && holder.customer.name ? holder.customer.name : ''

      values.vehicleDetails.dataSource = res.searchMeta ? res.searchMeta.dataSource : ''
      values.vehicleDetails.dataSourceLongText = res.searchMeta ? res.searchMeta.dataSourceLongText : ''
      values.vehicleDetails.dataSourceShortText = res.searchMeta ? res.searchMeta.dataSourceShortText : ''

      values.vehicleInfoCheckId = res.id
      setValues(values)
    }
  }

  useEffect(() => {
    setOwnersHoldersData(vehicleInfoCheck, setOwnersHolders)
  }, [vehicleInfoCheck, setOwnersHolders])

  const setSignatureFields = (signature: Maybe<SignatureInput>) => {
    if (signature == null) {
      setValue('signature.id', null)
      setValue('signature.type', SignatureType.written)
      setValue('signature.signatorName', '')
      setValue('signature.signatorEmail', '')
      setValue('signature.signedAt', '')
      setValue('signature.createdAt', null)
      setValue('signature.sentAt', null)
    }
    if (signature) {
      setValue('signature.id', signature.id)
      setValue('signature.type', signature.type)
      setValue('signature.signatorName', signature.signatorName)
      setValue('signature.signatorEmail', signature.signatorEmail)
      setValue('signature.signedAt', signature.signedAt)
      setValue('signature.agreements', signature.agreements)
      setValue('signature.createdAt', signature.createdAt)
      setValue('signature.sentAt', signature.sentAt)
    }
  }

  const getValue = (fieldName: string) => {
    const val = getIn(values, fieldName)
    return val
  }

  const signaturePadRef = useSignatureCanvasRef()
  const paymentRef: RefObject<any> = React.createRef()
  const sosRef: RefObject<any> = React.createRef()
  const atjRef: RefObject<any> = React.createRef()
  const billingRef: RefObject<any> = React.createRef()

  const {
    state: { currentUser, settings },
  } = useContext(StateStore)

  const pendingPaymentTerminalTransactions = () => {
    if (values && values.billing && values.billing.paymentTerminalTransactions) {
      return (
        values.billing.paymentTerminalTransactions.filter(function(t) {
          return t.transactionStatus === 'pending'
        }).length > 0
      )
    }
    return false
  }

  useEffect(() => {
    if (scrollTo === 'atj' && atjRef.current) {
      atjRef.current.scrollIntoView()
    }
    if (scrollTo === 'billing') {
      billingRef.current.scrollIntoView()
    }
  }, [scrollTo, atjRef, billingRef])

  return (
    <>
      {loading && <CenteredLoader />}
      <FormSubGroupsList>
        <TowingRecordSettings
          isAl={values.type === TowingRecordType.autoliitto}
          disableOperator={disableOperator}
          isSos={values.type === TowingRecordType.sos}
          userRole={currentUser ? currentUser.role : null}
          notificationSettings={settings ? settings.notifications : null}
        />

        <VehicleInfoFormFields
          formatMessage={formatMessage}
          onHandleVehicleResults={handleVehicleResults}
          onCancelVehicleDetails={handleResetVehicleInfoCheck}
          registrationNumber={values.vehicleDetails.registrationNumber}
          getValue={getValue}
          setValue={setValue}
        />

        <CustomerFormFields
          getValue={getValue}
          editCustomer={() => setShowCustomerEditForm(true)}
          orderer={values.orderer}
          clearOrderer={() => setOrderer(initialOrderer)}
          onUpdate={orderer => setOrderer(orderer)}
          ssn={
            values.orderer && values.orderer.personCustomer && values.orderer.personCustomer.ssn
              ? values.orderer.personCustomer.ssn
              : ''
          }
          businessId={
            values.orderer && values.orderer.companyCustomer && values.orderer.companyCustomer.businessId
              ? values.orderer.companyCustomer.businessId
              : ''
          }
          setFieldValue={setValue}
          isSos={!!values.sosServiceOrder}
          infoSearchModalButtonLabel="Vie kuormakirjalle"
          ownersHolders={ownersHolders}
          setOrdererFromTrafi={setOrdererFromTrafi}
        />
        {values.typeIdentifier !== TypeIdentifier.sos &&
          values.typeIdentifier !== TypeIdentifier.autoliitto &&
          values.typeIdentifier !== TypeIdentifier.lahitapiola &&
          !isServiceOrderDraft && (
            <FormSubGroupWrapper>
              <TowingRecordDetailsSubFormContents />
            </FormSubGroupWrapper>
          )}
        {(values.typeIdentifier === TypeIdentifier.sos || isServiceOrderDraft) && (
          <SosServiceOrderDetailsForm
            isDraft={isServiceOrderDraft}
            sosRef={sosRef}
            scrollToThis={isServiceOrderDraft}
            setValue={setValue}
            getValue={getValue}
          />
        )}
        {values.typeIdentifier === TypeIdentifier.autoliitto && !isServiceOrderDraft && (
          <ALSubForm serviceTypeOptions={translatedServiceTypeOptions} serviceType={values.jobDetails.serviceType} />
        )}
        {isServiceOrderDraft && (
          <TowingOrderRoutesForm
            getValue={getValue}
            setValue={setValue}
            orderType={TowingOrderType.sos}
            deliverableName="sosServiceOrder.deliverable"
          />
        )}
        {values.type === TowingRecordType.sos && values.typeIdentifier === TypeIdentifier.lahitapiola && (
          <LahitapiolaSosOrderDetailsForm getValue={getValue} setValue={setValue} isTowingRecord />
        )}
        {values.typeIdentifier === TypeIdentifier.lahitapiola && values.type !== TowingRecordType.sos && (
          <LahitapiolaDefaultOrderDetailsForm isTowingRecord />
        )}
        <FormSubGroupWrapper>
          <TowingRecordItems
            towingRecordId={values.id}
            setValue={setValue}
            jobItems={values.jobItems}
            discountItem={values.discountItem}
            itemsIncludeVat={values.itemsIncludeVat}
            disabled={
              values.status === TowingRecordStatus.complete ||
              pendingPaymentTerminalTransactions() ||
              values.billing.paymentDetails.id !== null
            }
            paymentRef={paymentRef}
            scrollToPayment={scrollTo === 'payment'}
          />
        </FormSubGroupWrapper>
        <FormSubGroupWrapper>
          <Signature
            signature={values.signature}
            towingRecordId={values.id}
            setSignatureFields={setSignatureFields}
            sigPadRef={signaturePadRef}
            setSaveSignature={(value: boolean) => setValue('saveSignature', value)}
            orderer={values.orderer}
          />
        </FormSubGroupWrapper>
        <FormSubGroupWrapper>
          <TowingRecordBilling
            itemsTotal={getItemsTotal({
              discountItem: values.discountItem,
              jobItems: values.jobItems,
              itemsIncludeVat: values.itemsIncludeVat,
            })}
            values={values}
            hasFormChanged={hasFormChanged}
            paymentType={values.paymentType}
            onChange={(debtorType: string) => setValues(populateBillingFieldsByDebtorType(values, debtorType))}
            disabled={
              (values.status !== 'unfinished' && values.status !== 'billing') || pendingPaymentTerminalTransactions()
            }
            billingRef={billingRef}
          />
        </FormSubGroupWrapper>
      </FormSubGroupsList>
    </>
  )
}
