import React from 'react'
import { FormatMessageType } from '../../common/types'
import { HalfButton } from '../Button'
import {
  FormSubGroupWrapper,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroup,
  Section,
  SectionColumn,
  FieldButtonRow,
  Text,
} from '../layout'
import { TextInput } from '../layout/form/input'
import { SearchVehicleInfo } from '../SearchVehicleInfo'
import { VehicleInfo } from '../VehicleSearchModal/types'
import { messages } from '../../containers/TowingOrderPage/messages'
import { messages as buttonMessages } from '../Button/messages'
import { VehicleInfoDataSource } from './types'

interface VehicleInfoProps {
  formatMessage: FormatMessageType
  onHandleVehicleResults: (res: VehicleInfo) => void
  onCancelVehicleDetails: () => void
  registrationNumber: string
  getValue: any
  setValue: (field: string, value: string) => void
}

export const VehicleInfoFormFields = ({
  formatMessage,
  onHandleVehicleResults,
  getValue,
  onCancelVehicleDetails,
  registrationNumber,
  setValue
}: VehicleInfoProps) => {
  const disableEdit = getValue('vehicleDetails.dataSource') === VehicleInfoDataSource.traficom
  return (
    <FormSubGroupWrapper>
      <FormSubGroupHeader>
        <FormSubGroupHeading>{formatMessage(messages.vehicleDetails)}</FormSubGroupHeading>
      </FormSubGroupHeader>
      <FormSubGroup>
        <Section>
          <SectionColumn>
            <FieldButtonRow sameBorderRadius>
              <TextInput
                label={formatMessage(messages.registrationNumber)}
                name="vehicleDetails.registrationNumber"
                required
                toUpperCase={true}
                disabled={disableEdit}
              />
              <SearchVehicleInfo
                getValue={getValue}
                setValue={setValue}
                buttonText="Vie tilaukseen"
                handleResults={onHandleVehicleResults}
                searchString={registrationNumber}
                vehicleInfoCheckId={getValue('vehicleInfoCheckId')}
              />
            </FieldButtonRow>
          </SectionColumn>

          <SectionColumn>
            <TextInput
              label={formatMessage(messages.vehicleClass)}
              name="vehicleDetails.vehicleClass"
              disabled={disableEdit}
            />
          </SectionColumn>
          <SectionColumn>
            <TextInput
              label={formatMessage(messages.makeAndModel)}
              name="vehicleDetails.makeAndModel"
              disabled={disableEdit}
            />
          </SectionColumn>
          <SectionColumn>
            <TextInput
              label={formatMessage(messages.insuranceCompany)}
              name="vehicleDetails.insuranceCompany"
              disabled={disableEdit}
            />
          </SectionColumn>
          <SectionColumn>
            <TextInput label={formatMessage(messages.owner)} name="vehicleDetails.owner" disabled={disableEdit} />
          </SectionColumn>
          <SectionColumn>
            <TextInput label={formatMessage(messages.holder)} name="vehicleDetails.holder" disabled={disableEdit} />
          </SectionColumn>
        </Section>
        <Section>
          <SectionColumn hideOnMobile />
          <SectionColumn hideOnMobile hideOnTablet />
          <SectionColumn flexDirection="column" alignItems="flex-end">
          
            <HalfButton
              category="cancelSecondary"
              label={formatMessage(buttonMessages.clear)}
              onClick={() => onCancelVehicleDetails()}
            />
          </SectionColumn>
        </Section>
        <Text margin="-18px 0 0px 0" color="grey400" fontSize="12px">
            {getValue('vehicleDetails.dataSourceLongText')}
        </Text>
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}
