import { toScalarBusinessId, toScalarEmail, toScalarPhone, toScalarPostalCode } from '../../../util/convertToScalar'
import { CompanyCustomerFormValues } from '../types'
import { MutationInput } from './types'
import { isStringNullOrEmpty } from '../../../util/common'

export const toMutationVariables = (id: number, values: CompanyCustomerFormValues): MutationInput => {
  return {
    input: {
      id,
      customer: {
        companyName: values.companyName,
        businessId: isStringNullOrEmpty(values.businessId) ? null : toScalarBusinessId(values.businessId),
        contactPerson: values.contactPerson,
        contactPersonEmail: isStringNullOrEmpty(values.contactPersonEmail)
          ? null
          : toScalarEmail(values.contactPersonEmail),
        phone: isStringNullOrEmpty(values.phone) ? null : toScalarPhone(values.phone),
        address: values.address,
        additionalInfo: values.additionalInfo,
        city: values.city === '' ? null : values.city,
        co: values.co,
        identifier: values.identifier,
        deliveryMethod: values.deliveryMethod,
        eInvoiceAddress: values.eInvoiceAddress,
        eInvoiceOperator: values.eInvoiceOperator,
        email: isStringNullOrEmpty(values.email) ? null : toScalarEmail(values.email),
        extraInfo: values.extraInfo,
        zipcode: isStringNullOrEmpty(values.zipcode) ? null : toScalarPostalCode(values.zipcode),
        notificationMethod: values.notificationMethod,
        //Customer data from trafi cannot be modified, so reset source to identify modified customer
        source: ''
      },
    },
  }
}
