import React from 'react'
import styled from 'styled-components'
import uuid from 'uuid'

import { isStringNullOrEmpty, ucfirst } from '../../util/common'
import { toLocaleDateString } from '../../util/dateConversion'
import { HeaderAndDateToDate, HeaderAndMultipleValues, HeaderAndValue } from '../DisplayFields'
import { Section } from '../layout/section'
import { Heading4 } from '../layout/typography'
import { OwnersHolder, VehicleInfo } from './types'
import { TransferPermit } from '../../functions/graphqlApi/types/vehicleInfo'
import Maybe from 'graphql/tsutils/Maybe'

interface Props {
  vehicleInfo: VehicleInfo
  reFetch: () => void
}

const TrafiSearchContainer = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`

const ColumnHeader = styled(Heading4)`
  border-bottom: 2px solid lightgray;
  // margin-right: 1rem;
`

const TrafiSearch: React.FunctionComponent<Props> = ({ vehicleInfo }) => {
  const owners = vehicleInfo.ownersHolders || []

  return (
    <TrafiSearchContainer>
      <>
        <ColumnHeader>Tekniset tiedot</ColumnHeader>
        <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
          <HeaderAndValue header="Rekisteritunnus" value={vehicleInfo.vehicle.registrationNumber || 'Ei tiedossa'} />
          <HeaderAndValue header="Ajoneuvoluokka" value={vehicleInfo.vehicle.vehicleClass || 'Ei tiedossa'} />
          <HeaderAndValue header="Merkki ja malli" value={vehicleInfo.vehicle.makeAndModel || 'Ei tiedossa'} />
          {vehicleInfo.vehicle.modelYear && (
            <HeaderAndValue header="Vuosimalli" value={vehicleInfo.vehicle.modelYear} />
          )}
          <HeaderAndValue header="Valmistenumero" value={vehicleInfo.vehicle.serialNumber || 'Ei tiedossa'} />
          <HeaderAndValue header="Ajoneuvon tila" value={vehicleInfo.vehicle.vehicleStatus} />
          <HeaderAndValue header="Väri" value={vehicleInfo.vehicle.color || 'Ei tiedossa'} />
          <HeaderAndValue header="Käyttövoima" value={vehicleInfo.vehicle.fuel || 'Ei tiedossa'} />
          {vehicleInfo.vehicle.driveTrain && (
            <HeaderAndValue header="Voimansiirto" value={vehicleInfo.vehicle.driveTrain} />
          )}
          {vehicleInfo.vehicle.transmission && (
            <HeaderAndValue header="Vaihteisto" value={vehicleInfo.vehicle.transmission} />
          )}
          {vehicleInfo.vehicle.displacement && (
            <HeaderAndValue header="Iskutilavuus" value={`${vehicleInfo.vehicle.displacement} cm³`} />
          )}
          {vehicleInfo.vehicle.maxNetEffect && (
            <HeaderAndValue header="Suurin nettoteho" value={`${vehicleInfo.vehicle.maxNetEffect} kW`} />
          )}
          {vehicleInfo.vehicle.weight && (
            <HeaderAndValue header="Omamassa" value={`${vehicleInfo.vehicle.weight} kg`} />
          )}
          {vehicleInfo.vehicle.maxNetWeight && (
            <HeaderAndValue
              header="Tieliikenteessä suurin sallittu kokonaismassa"
              value={`${vehicleInfo.vehicle.maxNetWeight} kg`}
            />
          )}
          {vehicleInfo.vehicle.maxTechNetWeight && (
            <HeaderAndValue
              header="Tekninen suurin sallittu kokonaismassa"
              value={`${vehicleInfo.vehicle.maxTechNetWeight} kg`}
            />
          )}
          {vehicleInfo.vehicle.length && <HeaderAndValue header="Pituus" value={`${vehicleInfo.vehicle.length} mm`} />}
          {vehicleInfo.vehicle.width && <HeaderAndValue header="Leveys" value={`${vehicleInfo.vehicle.width} mm`} />}
          {vehicleInfo.vehicle.height && <HeaderAndValue header="Korkeus" value={`${vehicleInfo.vehicle.height} mm`} />}
        </Section>

        <ColumnHeader>Rekisteri ja katsastustiedot</ColumnHeader>
        <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
          <HeaderAndValue header="Käyttöönottopvm" value={vehicleInfo.vehicle.initializationDate || 'Ei tiedossa'} />
          <HeaderAndValue
            header="Edellinen katsastus, päätös ja pvm"
            value={`${ucfirst(vehicleInfo.vehicle.lastInspectionResultDescription || '')} ${toLocaleDateString(
              vehicleInfo.vehicle.lastInspectionDate
            )}`}
          />
          <HeaderAndValue header="Seuraava katsastus" value={vehicleInfo.vehicle.nextInspectionDate || 'Ei tiedossa'} />
          <HeaderAndValue header="Ensirekisteröintipvm" value={vehicleInfo.vehicle.firstRegistrationDate || 'Ei tiedossa'} />
        </Section>

        <ColumnHeader>Vakuutustiedot (Liikennevakuutus)</ColumnHeader>
        <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
          <HeaderAndValue header="Vakuutusyhtiö" value={vehicleInfo.insurance.insuranceCompany || 'Ei tiedossa'} />
          <HeaderAndDateToDate
            header="Vakuutuksen alku- ja loppupvm"
            startDate={vehicleInfo.insurance.insuranceStartDate}
            endDate={vehicleInfo.insurance.insuranceEndDate}
          />
          <HeaderAndValue header="Vakuutuksenottaja" value={vehicleInfo.insurance.insuredParty || 'Ei tiedossa'} />
        </Section>

        {owners.map(o => {
          return (
            <div key={uuid()}>
              <ColumnHeader>{o.ownershipTypeDescription}</ColumnHeader>
              <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                <OwnersRow owner={o} />
              </Section>
            </div>
          )
        })}
        {vehicleInfo.transferPermit && (
          <>
            <ColumnHeader>Ajoneuvolla on siirtolupa</ColumnHeader>
            <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
              <HeaderAndValue header="Tarkoitus" value={vehicleInfo.transferPermit.purposeOfUse} />
              <HeaderAndValue
                header="Voimassaoloaika"
                value={vehicleInfo.transferPermit.validityStarts + ' - ' + vehicleInfo.transferPermit.validityEnds}
              />
              <HeaderAndValue header="Hakija" value={applicantInfo(vehicleInfo.transferPermit)} />
              <HeaderAndValue header="Reitti" value={vehicleInfo.transferPermit.route} />
            </Section>
          </>
        )}
        {(!isStringNullOrEmpty(vehicleInfo.vehicle.remarks) || vehicleInfo.restrictions.length > 0) && (
          <>
            {vehicleInfo.restrictions.length > 0 && (
              <>
                <ColumnHeader>Ajoneuvolla rajoitustietoja</ColumnHeader>
                {vehicleInfo.restrictions.map((r, i) => {
                  return (
                    <div key={uuid()}>
                      <Section gap="2rem" justify="text" columns={2} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                        <HeaderAndValue header="Rajoituksen tyyppi" value={r.description} />
                        <HeaderAndValue
                          header="Vakavuus 1-5"
                          value={`${r.seriousnessLevel}, ${r.seriousnessDescription}`}
                        />
                      </Section>
                      {i < vehicleInfo.restrictions.length - 1 && <ColumnHeader />}
                    </div>
                  )
                })}
              </>
            )}
            {!isStringNullOrEmpty(vehicleInfo.vehicle.remarks) && (
              <>
                <ColumnHeader>Ajoneuvolla seuraavat rajoitustiedot</ColumnHeader>
                <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                  <HeaderAndValue header="Rajoitukset" value={vehicleInfo.vehicle.remarks} />
                </Section>
              </>
            )}
          </>
        )}
       
      </>
    </TrafiSearchContainer>
  )
}

function applicantInfo(transferPermit: Maybe<TransferPermit>) {

  if ( !transferPermit || !transferPermit.applicant.firstNameSubUnit) {
    return 'Ei saatavilla'
  }

  const { lastNameMainUnit, firstNameSubUnit, streetAddress, postalCode, city } = transferPermit.applicant

  const name = `${lastNameMainUnit + ' ' + firstNameSubUnit}`
  const address = `${streetAddress + ', ' + postalCode + ' ' + city}`

  return `${name}, ${address}`
}

interface OwnersRowProps {
  owner: OwnersHolder
}
const OwnersRow = ({ owner }: OwnersRowProps) => {

  const { identifier, name, address, ownershipStartDate, ownershipEndDate } = getRowTexts(owner)

  return (
    <>
      <HeaderAndValue header="Nimi" value={name} />
      {owner.customer && owner.customer.type === 'company' && (
        <HeaderAndValue header="Y-tunnus" value={identifier} />
      )}
      <HeaderAndMultipleValues
        header="Osoite"
        values={address}
      />
      {ownershipStartDate || ownershipEndDate ? (
        <HeaderAndDateToDate
          header="Omistussuhteen alku- ja loppupvm"
          startDate={ownershipStartDate}
          endDate={ownershipEndDate}
        />
      ) : (
        <HeaderAndValue
          header="Omistussuhteen alku- ja loppupvm"
          value={"Ei saatavilla"}
        />
      )}
    </>
  )
} 

export default TrafiSearch

function getRowTexts(owner: OwnersHolder) {
  const customer = owner.customer;

  const emptyField = 'Ei saatavilla'
  const empty = 'Ei tiedossa'

  const identifier = customer && customer.identifier ? customer.identifier : emptyField
  const name = customer && customer.name ? customer.name : emptyField

  const zipCode = owner.customer && owner.customer.zipcode ? owner.customer.zipcode : empty
  const city = owner.customer && owner.customer.city ? owner.customer.city : empty
  const customerAddress = owner.customer && owner.customer.address ? owner.customer.address : empty
  const address = [`${customerAddress}`, `${zipCode}, ${city}`] 

  const ownershipStartDate = owner.ownershipStartDate ? owner.ownershipStartDate : '';
  const ownershipEndDate = owner.ownershipEndDate ? owner.ownershipEndDate : '';
  
  return { identifier, name, address, ownershipStartDate, ownershipEndDate }
}
