import React from 'react'
import { trafiSourceText } from '../../common/messages'
import { Text } from '../layout'

interface TraficomInfoTextProps {
  show: boolean
  margin?: string
}

export const TraficomInfoText = ({ show, margin }: TraficomInfoTextProps) => {
  const defaultMargin = '20px 0 -30px 0'
  return (
    <Text hidden={!show} margin={`${margin ? margin : defaultMargin}`} color="grey400" fontSize="12px">
      {trafiSourceText}
    </Text>
  )
}
