import {
  CustomerType,
  DeliveryMethod,
  NotificationMethod,
  SignatureType,
  TowingRecordServiceType,
  TowingRecordStatus,
  TowingRecordType,
} from '../../../common/types'
import { VehicleInfoDataSource } from '../../../components/VehicleDetails/types'
import { CustomerFormValues } from '../../Customer/types'
import { InitialValues } from './types'

export const initialCompanyCustomer = {
  companyName: '',
  businessId: '',
  contactPerson: '',
  contactPersonEmail: '',
  phone: '',
  address: '',
  identifier: '',
  extraInfo: '',
  additionalInfo: '',
  co: '',
  zipcode: '',
  city: '',
  eInvoiceAddress: '',
  eInvoiceOperator: '',
  paymentTerms: 14,
  displayInCustomerRegistry: false,
  deliveryMethod: DeliveryMethod.no_delivery,
  email: '',
  notificationMethod: 'disabled' as NotificationMethod,
  originatorApp: null,
  source: ''
}

export const initialPersonCustomer = {
  ssn: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  identifier: '',
  extraInfo: '',
  additionalInfo: '',
  co: '',
  zipcode: '',
  city: '',
  eInvoiceAddress: '',
  eInvoiceOperator: '',
  paymentTerms: 14,
  displayInCustomerRegistry: false,
  deliveryMethod: DeliveryMethod.no_delivery,
  notificationMethod: 'disabled' as NotificationMethod,
  originatorApp: null,
  source: ''
}

export const initialOrderer: CustomerFormValues = {
  id: null,
  type: CustomerType.person,
  addToRegistry: false,
  displayInCustomerRegistry: false,
  personCustomer: initialPersonCustomer,
  companyCustomer: initialCompanyCustomer,
}

export const initialVehicleDetails = {
  registrationNumber: '',
  vehicleClass: '',
  makeAndModel: '',
  insuranceCompany: '',
  owner: '',
  holder: '',
  dataSource: VehicleInfoDataSource.default,
  dataSourceLongText: '',
  dataSourceShortText: ''
}

export function getInitialValues(): InitialValues {
  return {
    status: TowingRecordStatus.unfinished,
    recordNumber: '',
    type: TowingRecordType.default,
    paymentType: null,
    operatorId: null,
    stationId: null,
    vehicleInfoCheckId: null,
    label: '',
    itemsIncludeVat: false,
    onCall: false,
    tags: '',
    vehicleDetails: initialVehicleDetails,
    jobDetails: {
      serviceType: TowingRecordServiceType.TOWING,
      orderReference: '',
      towingDate: new Date(),
      towingReason: '',
      additionalInfo: '',
      notes: '',
      incidentCaseNo: null,
    },
    jobItems: [],
    discountItem: null,
    routes: [],
    billing: {
      type: null,
      paymentDetails: {
        id: null,
        debtorName: '',
        receiptEmail: '',
        paymentDate: new Date(),
        source: ''
      },
      paymentTerminalTransactions: []
    },
    signature: {
      signatorName: '',
      signatorEmail: '',
      signatorPhone: '',
      type: SignatureType.written,
      agreements: [],
      data: '',
    },
    orderer: initialOrderer,
    startPlaceOption: 'write_address',
    itemGroup: null,
    item: null,
    saveSignature: false,
    automobileAndTouringClubFinland: null,
    sosServiceOrder: null,
  }
}
