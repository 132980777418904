import { Maybe, Time, TowingOrderServiceType } from '../../../common/types'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { VisibilityType } from '../types'
import { AddTowingOrderFormValues } from './types'
import { initialSosServiceOrder } from '../../../components/SosSubForm/types'
import { Settings } from '../../Parameters/types'
import { VehicleInfoDataSource } from '../../../components/VehicleDetails/types'

const initialCoordinates = {
  lat: null,
  long: null,
}

export const initialVehicleDetails = {
  registrationNumber: '',
  vehicleClass: '',
  makeAndModel: '',
  insuranceCompany: '',
  owner: '',
  holder: '',
  dataSource: VehicleInfoDataSource.default,
  dataSourceLongText: '',
  dataSourceShortText: '',
}

export const initialValues: AddTowingOrderFormValues = {
  assignOnSave: false,
  visibility: 'open' as VisibilityType,
  stationId: null,
  operatorId: null,
  vehicleInfoCheckId: null,
  label: '',
  orderer: initialOrderer,
  vehicleDetails: Object.assign({}, initialVehicleDetails),
  jobDetails: {
    serviceType: TowingOrderServiceType.TOWING,
    orderReference: '',
    towingDate: new Date(),
    towingDateType: 'single',
    towingEndDate: new Date(),
    towingTime: ('' as unknown) as Time,
    towingEndTime: ('' as unknown) as Time,
    towingReason: '',
    additionalInfo: '',
    notes: '',
    incidentCaseNo: null,
  },
  from: {
    address: '',
    city: '',
    description: '',
    zipcode: '',
    coords: initialCoordinates,
  },
  to: {
    address: '',
    city: '',
    description: '',
    zipcode: '',
    coords: initialCoordinates,
  },
  sosServiceOrder: null,
  notificationMethod: 'disabled', // TODO: Change this to function to determine correct value
}

export const getInitialValues = (isSos: boolean, settings: Maybe<Settings>) => {
  if (isSos) {
    initialValues.sosServiceOrder = Object.assign({}, initialSosServiceOrder)
  } else {
    initialValues.sosServiceOrder = null
  }

  if (settings && settings.notifications && settings.notifications.enabled) {
    initialValues.notificationMethod = settings.notifications.method
  }

  return initialValues
}
