import { CustomerType, Maybe } from '../../../common/types'
import {
  toSosServiceOrderEditMutationVariables,
  toSosServiceOrderMutationVariables,
} from '../../../components/SosSubForm/mutation/toMutationVariables'
import { CompanyCustomerFormValues, PersonCustomerFormValues } from '../../../containers/Customer/types'
import {
  toScalarBusinessId,
  toScalarEmail,
  toScalarPersonalIdentityCode,
  toScalarPhone,
  toScalarShortDate,
  toScalarTime,
} from '../../../util/convertToScalar'
import { toALScalarTimestamp } from '../../TowingRecordPage/components/routes/subforms/ArrivalRoute/mutation/toMutationVariables'
import { AutomobileAndTouringClubFinlandInput } from '../../TowingRecordPage/EditTowingRecord/types'
import { AutomobileAndTouringClubFinlandInputMutation } from '../../TowingRecordPage/towingRecordInput/types'
import { AddTowingOrderFormValues } from '../AddTowingOrder/types'
import { EditTowingOrderFormValues } from '../EditTowingOrder/types'
import { CompanyCustomerMutationInput, PersonCustomerMutationInput, TowingOrderMutationInput } from './types'

type InputValues = AddTowingOrderFormValues | EditTowingOrderFormValues

export const toTowingOrderInput = (
  inputValues: InputValues,
  operatorId: Maybe<number> = null
): TowingOrderMutationInput => {
  return {
    assignOnSave: operatorId === inputValues.operatorId && (inputValues as InputValues).assignOnSave,
    label: inputValues.label,
    stationId: inputValues.stationId,
    operatorId: inputValues.operatorId,
    vehicleInfoCheckId: inputValues.vehicleInfoCheckId ? inputValues.vehicleInfoCheckId : null,
    visibility: (inputValues as AddTowingOrderFormValues).assignOnSave ? 'assigned' : inputValues.visibility,
    notificationMethod: inputValues.notificationMethod,
    from: {
      address: inputValues.from.address,
      city: inputValues.from.city,
      description: inputValues.from.description,
      zipcode: inputValues.from.zipcode,
      coords: {
        lat: inputValues.from.coords.lat,
        long: inputValues.from.coords.long,
      },
    },
    to: {
      address: inputValues.to.address,
      city: inputValues.to.city,
      description: inputValues.to.description,
      zipcode: inputValues.to.zipcode,
      coords: {
        lat: inputValues.to.coords.lat,
        long: inputValues.to.coords.long,
      },
    },
    vehicleDetails: {
      registrationNumber: inputValues.vehicleDetails.registrationNumber,
      vehicleClass: inputValues.vehicleDetails.vehicleClass,
      makeAndModel: inputValues.vehicleDetails.makeAndModel,
      insuranceCompany: inputValues.vehicleDetails.insuranceCompany,
      owner: inputValues.vehicleDetails.owner,
      holder: inputValues.vehicleDetails.holder,
    },
    jobDetails: {
      orderReference: inputValues.jobDetails.orderReference,
      serviceType: inputValues.jobDetails.serviceType,
      towingDateType: inputValues.jobDetails.towingDateType || 'single',
      towingDate: inputValues.jobDetails.towingDate ? toScalarShortDate(inputValues.jobDetails.towingDate) : null,
      towingEndDate:
        inputValues.jobDetails.towingEndDate && inputValues.jobDetails.towingDateType === 'span'
          ? toScalarShortDate(inputValues.jobDetails.towingEndDate)
          : null,
      towingTime: inputValues.jobDetails.towingTime ? toScalarTime(inputValues.jobDetails.towingTime) : null,
      towingEndTime: inputValues.jobDetails.towingEndTime ? toScalarTime(inputValues.jobDetails.towingEndTime) : null,
      towingReason: inputValues.jobDetails.towingReason,
      additionalInfo: inputValues.jobDetails.additionalInfo,
      notes: inputValues.jobDetails.notes,
      incidentCaseNo: inputValues.jobDetails.incidentCaseNo,
    },
    orderer: {
      id: inputValues.orderer.id,
      addToRegistry: inputValues.orderer.addToRegistry,
      personCustomer:
        inputValues.orderer.type === CustomerType.person && inputValues.orderer.personCustomer
          ? toPersonCustomerMutationVariables(inputValues.orderer.personCustomer)
          : null,
      companyCustomer:
        inputValues.orderer.type === CustomerType.company && inputValues.orderer.companyCustomer
          ? toCompanyCustomerMutationVariables(inputValues.orderer.companyCustomer)
          : null,
    },
    sosServiceOrder: toSosServiceOrder(inputValues),
    automobileAndTouringClubFinland:
      'automobileAndTouringClubFinland' in inputValues && inputValues.automobileAndTouringClubFinland
        ? ALToMutationVariables(inputValues.automobileAndTouringClubFinland, inputValues.jobDetails)
        : null,
  }
}

const toSosServiceOrder = (inputValues: InputValues) => {
  if (!inputValues.sosServiceOrder) {
    return null
  }
  if ('id' in inputValues) {
    return toSosServiceOrderEditMutationVariables(inputValues.sosServiceOrder)
  }
  return toSosServiceOrderMutationVariables(inputValues.sosServiceOrder)
}

const toPersonCustomerMutationVariables = (personCustomer: PersonCustomerFormValues): PersonCustomerMutationInput => {
  return {
    type: CustomerType.person,
    ssn: personCustomer.ssn ? toScalarPersonalIdentityCode(personCustomer.ssn) : null,
    firstName: personCustomer.firstName ? personCustomer.firstName : '',
    lastName: personCustomer.lastName ? personCustomer.lastName : '',
    email: personCustomer.email ? toScalarEmail(personCustomer.email) : null,
    phone: personCustomer.phone ? toScalarPhone(personCustomer.phone) : null,
    source: personCustomer.source
  }
}

const toCompanyCustomerMutationVariables = (
  companyCustomer: CompanyCustomerFormValues
): CompanyCustomerMutationInput => {
  return {
    type: CustomerType.company,
    companyName: companyCustomer.companyName ? companyCustomer.companyName : '',
    businessId: companyCustomer.businessId ? toScalarBusinessId(companyCustomer.businessId) : null,
    contactPerson: companyCustomer.contactPerson ? companyCustomer.contactPerson : '',
    contactPersonEmail: companyCustomer.contactPersonEmail ? toScalarEmail(companyCustomer.contactPersonEmail) : null,
    phone: companyCustomer.phone ? toScalarPhone(companyCustomer.phone) : null,
    source: companyCustomer.source
  }
}

const ALToMutationVariables = (
  al: AutomobileAndTouringClubFinlandInput,
  jobDetails: any
): AutomobileAndTouringClubFinlandInputMutation => {
  const {
    actualTimeOfArrival,
    actualTimeOfCompletion,
    estimatedTimeOfArrival,
    estimatedTimeOfCompletion,
  } = al.jobTimestamps
  return {
    arcCodes: {
      completion: {
        code: resolveALCompletionARC(jobDetails),
        label: null,
      },
      description: {
        code: al.arcCodes.description ? al.arcCodes.description.code : null,
        label: al.arcCodes.description ? al.arcCodes.description.label : null,
      },
      component: {
        code: al.arcCodes.component ? al.arcCodes.component.code : null,
        label: al.arcCodes.component ? al.arcCodes.component.label : null,
      },
    },
    caseNumber: al.caseNumber,
    delayedTowing: al.delayedTowing,
    delayedTowingReason: al.delayedTowingReason,
    distanceTowed: al.distanceTowed,
    jobId: al.jobId,
    personCount: al.personCount,
    repairShopId: al.repairShopId,
    serviceChannel: al.serviceChannel,
    towingInsistedByCustomer: al.towingInsistedByCustomer,
    vehicleMileage: al.vehicleMileage,
    jobTimestamps: {
      actualTimeOfArrival: actualTimeOfArrival ? toALScalarTimestamp(actualTimeOfArrival) : null,
      actualTimeOfCompletion: actualTimeOfCompletion ? toALScalarTimestamp(actualTimeOfCompletion) : null,
      estimatedTimeOfArrival: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
      estimatedTimeOfCompletion: estimatedTimeOfCompletion ? toALScalarTimestamp(estimatedTimeOfCompletion) : null,
    },
    billingInfo: al.billingInfo,
  }
}

const resolveALCompletionARC = (jobDetails: any) => {
  switch (jobDetails.serviceType) {
    case 'phone_service':
      return '12'
    case 'battery_service':
      return '68'
    case 'road_service':
      return '96'
    case 'towing':
      return '6G'
    default:
      return null
    // throw new Error(`Unrecognized service type for AL job '${jobDetails.serviceType}'`)
  }
}
