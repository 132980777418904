import React, { useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import { Button } from '../Button'
import VehicleSearchModal from '../VehicleSearchModal'
import { VehicleInfo } from '../VehicleSearchModal/types'
import { Maybe } from '../../common/types'

interface SearchVehicleInfoProps {
  searchString?: string | null
  buttonText?: string
  handleResults?: (vehicleInfo: VehicleInfo) => void
  vehicleInfoCheckId: Maybe<number>
  getValue: any
  setValue: (field: string, value: string) => void
}

export const SearchVehicleInfo: React.FunctionComponent<SearchVehicleInfoProps> = ({
  searchString = null,
  buttonText,
  handleResults,
  vehicleInfoCheckId,
  getValue,
  setValue
}) => {
  const [activeSearch, setActiveSearch] = useState<boolean>(false)
  const [currentSearchString, setCurrentSearchString] = useState<string | null>(searchString)

  return (
    <ApolloConsumer>
      {client => (
        <>
          {activeSearch && searchString && (
            <VehicleSearchModal
              getValue={getValue}
              setValue={setValue}
              vehicleRegistration={searchString}
              client={client}
              close={() => setActiveSearch(false)}
              buttonText={buttonText}
              vehicleInfoCheckId={
                vehicleInfoCheckId && currentSearchString === searchString ? vehicleInfoCheckId : null
              }
              buttonHandler={vehicleInfo => {
                setCurrentSearchString(searchString)
                if (handleResults) handleResults(vehicleInfo)
              }}
            />
          )}
          <Button
            size="s"
            category="search"
            disabled={searchString === ''}
            label={vehicleInfoCheckId && currentSearchString === searchString ? 'Näytä' : 'Hae'}
            onClick={() => {
              setActiveSearch(true)
            }}
          />
        </>
      )}
    </ApolloConsumer>
  )
}
